import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import BlockActions from "../BlockActions/BlockActions";
import DropDown from "./DropDown";
import "./MatchView.scss";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import ArtView from "../ArtView/ArtView";
import LearnerProperties from "../../LearnerProperties";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const lastItem = (arr) => {
  return arr[arr.length - 1];
};
const finalRes = (obj) => {
  const arr = Object.keys(obj);
  const resArr = [];
  for (let key of arr) {
    resArr.push({
      leftOption: key,
      rightOptions: obj[key],
    });
  }
  return resArr;
};

const MatchView = ({
  match,
  courseDetails,
  blockUserActivities,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  handleBlockAnswer,
  isHighlight,
}) => {
  let UserData = useSelector((state) => state.userLogin);
  const { cid } = useQueryParams();
  const { userDetails, completedModulesData, courseOne } = useOutletContext() || {};
  // const { setAuthStates } = useContext(AuthContext);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view

  const [disabled, setDisabled] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [res, setRes] = useState([{}]);
  const [loading, setloading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blockActivity, setBlockActivity] = useState({});
  const [showActions, setShowActions] = useState(false);

  const open = Boolean(anchorEl);
  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let moduleDetails = useSelector((state) => state.savedModuleDetail);

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  const toastRef = useRef();

  let location = useLocation();
  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item.block_id === match._id);
    if (data) {
      setBlockActivity(data);
      const newData = data?.matched_array?.reduce((acc, cur) => {
        acc[cur?.left_option] = cur?.right_option;
        return acc;
      }, {});
      setRes([newData]);
    }
    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [blockUserActivities]);

  const handleMatchSubmit = async (finalResponse) => {
    setDisabled(true);
    setloading(true);
    try {
      let body = {
        user_id: UserData?.data?.user?.id,
        course_id: cid,
        activity: "match",
        matched_array: finalResponse,
      };
      //Submit match block answers
      await POST(`${Constants.saveAndUpdateUserActivities}/${match?._id}`, body);
      toastRef.current.showToastMessage(
        "Your response has been submitted successfully.",
        "success",
      );
      setcheckUserInputs(true);
      handleBlockAnswer(match?._id);
      setDisabled(false);
      setloading(false);
      //save user last activity
      await POST(
        `${Constants.userActivity}?activity=${
          checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
        }`,
        {
          user: UserData?.data?.user,
        },
      );
      triggerUserEvent(checkUserInputs ? "update" : "save");
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  const onClick = (left, right) => {
    const arr = [...res];
    const result = arr.reduce(
      (acc, curr) => {
        if (curr[left]) {
          curr[left] = curr[left]?.includes(right)
            ? curr[left]?.filter((item) => item !== right)
            : [...curr[left], right];
          acc = [...acc, { ...curr }];
        } else {
          acc.push({ ...curr, [left]: [right] });
        }
        return acc;
      },
      [{}],
    );
    setRes([result[result?.length - 1]]);
  };

  const onSaveData = () => {
    if (isLoggedIn) {
      const finalResponse = finalRes(lastItem(res));
      const dbData = finalResponse?.reduce((acc, curr) => {
        if (curr.rightOptions?.length > 0) {
          acc.push(curr);
        }
        return acc;
      }, []);
      handleMatchSubmit(dbData);
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
      // setAuthStates((prev) => ({
      //   ...prev,
      //   loginOpen: true,
      //   modalOpen: true,
      //   forgotOpen: false,
      //   signupOpen: false,
      // }));
    }
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = "";
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="match-view">
          <div
            className={` container-block match  ${isHighlight && "highlightBlock"}
        ${match?.theme ? match?.theme : "postBgColor"}
      `}
            style={{
              background: `${match?.theme_type === "color" ? match?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
              <BlockActions
                blockId={match?._id}
                block={match}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}
            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}

            {isMobile && (
              <MenuIconWrapper onClick={handleMenuClick}>
                <MoreVertIcon fontSize="large" color="action" />
              </MenuIconWrapper>
            )}

            <p className="block-title">
              {match.is_required && <span className="required">*</span>}
              {match?.title}
            </p>
            {match?.text && (
              <div className="block-text" dangerouslySetInnerHTML={{ __html: match?.text }}></div>
            )}
            <div className="match-options">
              {match?.options && (
                <>
                  <div className="block-options block-options-left">
                    {match?.options?.map((item, index) => (
                      <React.Fragment key={index}>
                        {item?.is_optionb === false && (
                          <Grid
                            container
                            spacing={2}
                            className="match_row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <Grid
                              item
                              sm={6}
                              className={`block-option block-table ${
                                finalRes(lastItem(res)).find((lo) => lo.leftOption === item?._id)
                                  ? "bg-selected"
                                  : "bg-default"
                              }`}
                            >
                              {item?.image?.m && (
                                <div
                                  className="block-image"
                                  style={{
                                    backgroundImage: `url(${
                                      item?.image?.m?.includes(cloudfrontUrl)
                                        ? item?.image?.m
                                        : cloudfrontUrl + item?.image?.m
                                    })`,
                                    height: `${item?.bound || "200"}px`,
                                  }}
                                ></div>
                              )}
                              <div className="block-details">
                                <div
                                  className="block-text"
                                  dangerouslySetInnerHTML={{ __html: item?.text }}
                                ></div>
                              </div>
                            </Grid>
                            <Grid item sm={6} className="fullwidth">
                              <DropDown
                                selected={res[0][item?._id] || []}
                                options={match?.options}
                                leftOptionId={item?._id}
                                onClick={onClick}
                                disableBlock={disableBlock}
                                isLoggedIn={isLoggedIn}
                                sx={{
                                  maxWidth: "100%", // Ensure dropdown does not exceed the block's width
                                  textOverflow: "ellipsis", // Truncate overflow text
                                  whiteSpace: "nowrap", // Prevent wrapping
                                  overflow: "hidden", // Hide overflowing content
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
            </div>
            {match?.art?.m && <ArtView art={(block || match)?.art} />}
            <ButtonMain
              onClick={() => onSaveData()}
              disabled={disabled || disableBlock || !isLoggedIn}
              style={{ padding: `${loading ? "5px 25px" : ""}` }}
            >
              {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
            </ButtonMain>
            {userDetails?.role === userRoles.REVIEWER && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleBlockProperties}
                sx={{ float: "right" }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
          <ToastNotify ref={toastRef} duration={3000} />
          {userDetails?.role === userRoles.REVIEWER && (
            <LearnerProperties
              anchorEl={anchorEl}
              open={open}
              handleClose={() => setAnchorEl(null)}
              obtainedMark={blockActivity?.credit}
              maxMark={block?.score || 0}
              blockType={block?.type}
              attempts={blockActivity?.NoOfAttempts}
              keywords={block?.keywords}
              scoreType={block?.typeOfBlock}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MatchView;
