import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { ParagraphText } from "../../Typography";
import "./CourseContainerView.scss";
import CarosalBlockView from "../courseViews/CarosalBlockView/CarosalBlockView";
import { AudioView } from "../courseViews/AudioView/AudioView";
import ButtonView from "../courseViews/ButtonView/ButtonView";
import ComicView from "../courseViews/ComicView/ComicView";
// import CommentView from "../courseViews/CommentView/CommentView";
import DiscussionView from "../courseViews/DiscussionView/DiscussionView";
import DividerView from "../courseViews/DividerView/DividerView";
import EmbedView from "../courseViews/EmbedView/EmbedView";
import GifView from "../courseViews/GifView/GifView";
import ImageView from "../courseViews/ImageView/ImageView";
import LinkView from "../courseViews/LinkView/LinkView";
import MCQView from "../courseViews/MCQView/MCQView";
import TextViews from "../courseViews/TextView/TextView";
import ToggleList from "../courseViews/ToggleListView/ToggleList";
import { VideoView } from "../courseViews/VideoView/VideoView";
import { FileView } from "../courseViews/FileView/FileView";
import FillView from "../courseViews/FillView/FillView";
import MatchView from "../courseViews/MatchView/MatchView";
import ResponseView from "../courseViews/ResponseView/ResponseView";
import GridView from "../courseViews/GridView/GridView";
import ListView from "../courseViews/ListView/ListView";
import { Box, Button, Grid, Typography, IconButton, Divider } from "@mui/material";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import KeywordsView from "../../Keywords/KeywordsView";
import { getS3ApiJsonKey, isLearnerView, useQueryParams } from "../../../utils";
import NothingHere from "../../NothingHere/NothingHere";
import CreateBlockModal from "../CreateBlockModal/CreateBlockModal";
import { saveModuleDetailAction } from "../../../redux/action";
import { GET_BREADCRUMB, POP_ONE_BREADCRUMB, PUSH_BREADCRUMB } from "../../../redux/constants";
import NativeEmbedView from "../courseViews/NativeEmbedView/NativeEmbedView";
import ScormView from "../courseViews/ScormView/ScormView";
import SurveyMonkeyView from "../courseViews/SurveyMonkeyView/SurveyMonkeyViews";
//import { useTheme } from "@mui/material/styles";
import clickEvent from "../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../utils/aws-utils";
import CustomizedSnackbars from "../../SnackBar/CustomizedSnackbars";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//import { ReactComponent as SectionIcon } from "../../../assets/sectionICON.svg";
import ContainerLeanerView from "../courseViews/ContainerLearnerView/ContainerLearnerView";
import GameView from "../courseViews/GameView/GameView";
import FSFormView from "../courseViews/FSFormView/FSFormView";

const CourseContainerViewV2 = ({
  courseDetails,
  setContainerBlocksData,
  completedModulesData,
  disableBlock,
  isHighlight,
  setisHighlight,
  onShowMADChange,
}) => {
  const [containerDetails, setContainerDetails] = useState([]);
  const [blockUserActivities, setBlockUserActivities] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [alertText, setalertText] = useState("");
  const [open, setOpen] = useState(false);
  const breadcrumbData = useSelector((state) => state.breadcrumb);
  const { courseOne, userDetails, moderator, handleBlockActivity } = useOutletContext();
  const moduleDetails = useSelector((state) => state.savedModuleDetail);
  const entrySource = useSelector((state) => state.entrySource.source);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const { cid, mid } = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [highlightedContainers, setHighlightedContainers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  //const theme = useTheme();
  const [inputValue, setInputValue] = useState(activeStep + 1);
  const { blockId } = location.state || {};
  const [highlightBlock, setHighlightBlock] = useState(blockId);
  const nonCarosalContainers = containerDetails.filter((block) => !block?.carousel_container);

  useEffect(() => {
    if (mid) {
      (async () => {
        try {
          setLoading(true);
          // start
          const url = `${getS3ApiJsonKey}/course/${cid}/mod-${mid}.json`;

          const response = await fetch(url);

          if (response.status === 200) {
            const data = await response.json();
            setContainerDetails(data);
            setContainerBlocksData(data);
          } else {
            //get course container details
            const response = await GET(`${Constants.getPublicCourseContainer}/${mid}/${cid}`);
            setContainerDetails(JSON.parse(response?.data?.data));
            setContainerBlocksData(JSON.parse(response?.data?.data));
          }

          // ends
          setLoading(false);
        } catch (error) {
          navigate("/404");
        }
      })();
      (async () => {
        try {
          //get container details
          const response = await GET(`${Constants.getPublicCourseBlock}/singleBlock/${mid}`);

          let moduleDetails = {
            containerTitle: response?.data?.data?.title,
            containerText: response?.data?.data?.text,
            id: response?.data?.data?._id,
            slug: response?.data?.data?.slug,
            type: response?.data?.data?.type,
          };
          dispatch(saveModuleDetailAction(moduleDetails));
        } catch (error) {
          navigate("/404");
        }
      })();
      triggerUserEvent();
    }
  }, [mid]);

  useEffect(() => {
    dispatch({ type: GET_BREADCRUMB });
    if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      !breadcrumbData.some((item) => item.title === moduleDetails?.data?.containerTitle)
    ) {
      const newBreadCrumb = {
        title: moduleDetails?.data?.containerTitle,
        navigate: `/course/${courseDetails?.data?.slug}/${
          moduleDetails?.data?.slug
        }?cid=${cid}&mid=${moduleDetails?.data?.id || mid}`,
      };
      dispatch({ type: PUSH_BREADCRUMB, payload: newBreadCrumb });
    }
  }, [moduleDetails]);

  const handleBlockAnswer = (blockId) => {
    setContainerDetails((prevDetails) =>
      prevDetails.map((block) =>
        block._id === blockId ? { ...block, ...containerDetails } : block,
      ),
    );
    setContainerBlocksData((prevDetails) =>
      prevDetails.map((block) =>
        block._id === blockId ? { ...block, ...containerDetails } : block,
      ),
    );
  };

  useEffect(() => {
    window.onpopstate = () => {
      dispatch({ type: POP_ONE_BREADCRUMB });
    };
  }, []);

  useEffect(() => {
    if (blockId) {
      setHighlightBlock(blockId);
    }
  }, [blockId]);

  useEffect(() => {
    isLoggedIn &&
      (async () => {
        try {
          //get block user activity
          const response = await GET(
            `${Constants.getPublicCourseBlock}/blockUserActivity/${cid}/${userDetails.user_id}`,
          );
          setBlockUserActivities(response?.data);
          handleBlockActivity(response?.data);
        } catch (error) {
          // navigate("/404");
          console.log(error);
        }
      })();
  }, [cid, mid, containerDetails, activeStep]);

  useEffect(() => {
    if (
      completedModulesData?.evaluationChecked === 1 &&
      courseDetails?.data?.assessmentDashboard?.blocksHighlight
    ) {
      blockUserActivities?.data?.map(
        (item) =>
          item?.isHighlight &&
          setisHighlight &&
          setisHighlight((prevState) => [...prevState, item.block_id]),
      );
    }
  }, [completedModulesData, mid, courseDetails]);

  const triggerUserEvent = () => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "module_load";
      eventData.user_unique_id = user.email;
      eventData.module_title = moduleDetails.data.containerTitle;
      eventData.block_id = moduleDetails.data.id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.module_type = moduleDetails.data.type;
      eventData.block_type = moduleDetails.data.type;
      eventData.module_open_ref = entrySource;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(
          sendDataToFirehose,
          completedModulesData.cohortDetails.eventTracking,
        );
      }
    }
  };

  const highlightParentContainers = (container) => {
    const parentContainerIds = container?.parentContainers || [];
    const highlighted = [];

    parentContainerIds.forEach((parentId) => {
      const foundModule = completedModulesData.modules.find(
        (module) => module.module_id === parentId,
      );
      if ((foundModule && foundModule.status !== "locked") || !foundModule) {
        highlighted.push(parentId);
      }
    });

    setHighlightedContainers(highlighted);
  };
  // const filteredBlocks = containerDetails.filter((item) => !item.carousel_container);

  // Function to group blocks based on section breaks and ignore empty sections
  const groupBlocksBySection = (blocks) => {
    let groupedBlocks = [];
    let currentGroup = [];

    blocks.forEach((block) => {
      if (block.type === "section_break") {
        if (currentGroup.length > 0) {
          groupedBlocks.push(currentGroup); // Only push if the current group is non-empty
        }
        currentGroup = []; // Start a new group after section break
      } else {
        currentGroup.push(block); // Add block to the current group if not a section break
      }
    });

    // Push the last group if it's non-empty
    if (currentGroup.length > 0 || groupedBlocks.length === 0) {
      groupedBlocks.push(currentGroup);
    }
    onShowMADChange(activeStep === groupedBlocks.length - 1);

    return groupedBlocks;
  };

  const groupedBlocks = groupBlocksBySection(nonCarosalContainers);
  const maxSteps = groupedBlocks.length;

  const updateUrlWithStep = (section) => {
    const params = new URLSearchParams(location.search);
    params.set("section", section);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const newStep = parseInt(e.target.value, 10); // Get input value as integer
      if (!isNaN(newStep) && newStep >= 1 && newStep <= maxSteps) {
        setActiveStep(newStep - 1);
      }
      setInputValue(newStep); // Update the input field with the value
      updateUrlWithStep(newStep - 1);
    }
  };

  useEffect(() => {
    if (activeStep >= maxSteps) {
      setActiveStep(0); // Reset to the first step if the new module has fewer sections
    }
  }, [groupedBlocks]);

  const handleNext = () => {
    if (activeStep < groupedBlocks.length - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setInputValue(nextStep + 1);
      updateUrlWithStep(nextStep);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
      setInputValue(prevStep + 1);
      updateUrlWithStep(prevStep);
    }
  };

  const handleScrollToBlock = (blockId) => {
    // Find the section index that contains the block with the specified blockId
    const sectionIndex = groupedBlocks.findIndex((section) =>
      section.some((item) => item._id === blockId),
    );

    // If the section is found, set the active step and scroll to the block
    if (sectionIndex !== -1) {
      setActiveStep(sectionIndex);
      setInputValue(sectionIndex + 1);
      updateUrlWithStep(sectionIndex);
    }

    // Smooth scroll to the block
    const blockElement = document.getElementById(blockId);

    if (blockElement) {
      blockElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    handleScrollToBlock(blockId);
  }, [blockId]);

  return (
    <div className="course-container">
      <CustomizedSnackbars
        //AlertText={alertText}
        AlertType={"error"}
        open={open}
        onClose={() => setOpen(false)}
      />
      <div className="breadcrumb-module-container">
        <h2>{moduleDetails?.data?.containerTitle || containerDetails?.[0]?.container?.title}</h2>
      </div>
      <ParagraphText>
        <div
          dangerouslySetInnerHTML={{
            __html:
              moduleDetails?.data?.containerText ||
              containerDetails.find((el) => el.type === "container")?.text,
          }}
        ></div>
      </ParagraphText>

      <KeywordsView keywords={courseDetails?.data || courseOne} />
      {moderator && (
        <Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{ mx: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/course/createblock/createcontainer?cid=${cid}&mid=${mid}`, "_blank");
            }}
          >
            Switch to Editor View
          </Button>
        </Box>
      )}

      {loading ? (
        <CourseDetailsSkeleton />
      ) : nonCarosalContainers.length > 0 ? (
        <Box
          sx={{
            border: maxSteps > 1 ? "1px solid" : "",
            borderColor: maxSteps > 1 ? "rgba(0, 0, 0, 0.12)" : "",
          }}
        >
          {maxSteps > 1 && (
            <Box
              sx={{
                position: "sticky",
                top: 110,
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%", p: 2, ml: 1 }}
              >
                {/* <Button variant="outlined" sx={{ textTransform: "none" }}> */}
                <Typography variant="h6" sx={{ mr: 1 }}>
                  {" "}
                  Section {activeStep + 1}{" "}
                </Typography>{" "}
                {/* <SectionIcon fontSize="small" /> */}
                {/* </Button> */}
                <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
                  <input
                    onKeyDown={handleKeyDown}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    variant="outlined"
                    size="medium"
                    style={{
                      width: 40,
                      padding: 0,
                      textAlign: "center",
                      border: "1px solid",
                      borderRadius: "15%",
                    }}
                  />

                  <Typography variant="h7" sx={{ mx: 1 }}>
                    of {maxSteps}
                  </Typography>

                  <IconButton
                    size="small"
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{
                      mx: 1,
                      border: "2px solid #06c",
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowBackIosNewIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    variant="outlined"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    sx={{
                      border: "2px solid #06c",
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowForwardIosIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>

              <Divider />
            </Box>
          )}
          <Grid container spacing={3} columns={100} sx={{ mb: 3, p: maxSteps > 1 ? 3 : 0 }}>
            {groupedBlocks[activeStep]?.map((item) => (
              <React.Fragment key={item._id}>
                {item?.type === "text" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width} id={item._id}>
                    <TextViews
                      block={item}
                      theme={item?.theme}
                      art={item?.art}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "button" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ButtonView
                      key={item}
                      button={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "divider" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <DividerView
                      key={item}
                      divider={item}
                      theme={isLoggedIn ? "light" : "dark"}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "toggle_list" && item?.items?.length > 0 ? (
                  <Grid item spacing={3} className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ToggleList
                      toggle_list={item?.items}
                      theme={item.theme}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {item?.type === "image" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ImageView
                      image={item?.image}
                      key={item?.image}
                      theme={item.theme}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "audio" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width} id={item._id}>
                    <AudioView
                      audio={item?.provider}
                      key={item?.provider}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "video" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <VideoView
                      video={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "file" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <FileView
                      file={item?.file}
                      data={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "link" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width} id={item._id}>
                    <LinkView
                      link={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "gif" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width} id={item._id}>
                    <GifView
                      gif={item?.gif}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "mcq" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <MCQView
                      mcq={item}
                      mcqs={item.mcqs}
                      blockUserActivities={blockUserActivities}
                      disableBlock={disableBlock}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      isHighlight={
                        isHighlight.some((block) => block === item._id) ||
                        highlightBlock === item._id
                      }
                      setisHighlight={setisHighlight}
                      handleBlockAnswer={handleBlockAnswer}
                    />
                  </Grid>
                )}
                {item?.type === "survey-monkey" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <SurveyMonkeyView
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                      isHighlight={highlightBlock === item._id}
                    />
                  </Grid>
                )}
                {item?.type === "game" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <GameView block={item} courseDetails={courseDetails.data || courseOne} />
                  </Grid>
                )}
                {item?.type === "fs-form" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <FSFormView
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                      isHighlight={highlightBlock === item._id}
                    />
                  </Grid>
                )}
                {item?.type === "scorm" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ScormView
                      scorm={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                  </Grid>
                )}
                {item?.type === "fill" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <FillView
                      fill={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                      isHighlight={
                        isHighlight.some((block) => block === item._id) ||
                        highlightBlock === item._id
                      }
                      handleBlockAnswer={handleBlockAnswer}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "match" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <MatchView
                      match={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                      handleBlockAnswer={handleBlockAnswer}
                      isHighlight={highlightBlock === item._id}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "grid" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <GridView
                      grid={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "response" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ResponseView
                      response={item}
                      art={item?.art}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                      isHighlight={
                        isHighlight.some((block) => block === item._id) ||
                        highlightBlock === item._id
                      }
                      setisHighlight={setisHighlight}
                      handleBlockAnswer={handleBlockAnswer}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "list" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ListView
                      list={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "embed" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <EmbedView embed={item} courseDetails={courseDetails.data || courseOne} />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "native-embed" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <NativeEmbedView
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                      setblockModOpen={setBlockModalOpen}
                    />
                  </Grid>
                )}
                {/* {item?.comments.length !== 0 && (
                  <Grid
                    item
                    className="fullwidth"
                    spacing={3}
                    lg={item?.size?.width}
                  >
                    <CommentView comments={item.comments} block={item} />
                  </Grid>
                )} */}
                {item?.type === "comic" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width} id={item._id}>
                    <ComicView
                      text={item.text}
                      image={item.image}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "container" && (
                  <Grid item className="fullwidth" md={item?.size?.width} id={item._id}>
                    <ContainerLeanerView
                      container={item}
                      completedModulesData={completedModulesData}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      ishighlighted={highlightedContainers.includes(item?._id)}
                      highlightParentContainers={highlightParentContainers}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}

                {item?.type === "carousel_container" && (
                  <Grid item className="fullwidth" lg={item?.size?.width} id={item._id}>
                    <CarosalBlockView
                      carosalContainer={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      isHighlight={isHighlight}
                      setisHighlight={setisHighlight}
                    />
                  </Grid>
                )}

                {item?.type === "discussion" && (
                  <Grid item md={item?.size?.width} id={item._id}>
                    <DiscussionView
                      discussion={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>

          {/* Stepper for navigation

          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                sx={{
                  mr: { md: "10rem", lg: "20rem" },
                }}
              >
                Continue
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{
                  ml: { md: "10rem", lg: "25rem" },
                }}
              >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          /> */}
        </Box>
      ) : (
        <NothingHere />
      )}
      <CreateBlockModal
        modalOpen={blockModalOpen}
        setblockModOpen={setBlockModalOpen}
        courseDetails={courseDetails}
        cid={cid}
      />
    </div>
  );
};

export default CourseContainerViewV2;
