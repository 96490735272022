import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import ButtonMain from "../../../../ButtonMain";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import "./AddRichText.scss";
import { useQueryParams } from "../../../../../utils";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";

// Font size array and dynamic size injection
const fontSizeArr = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
  "48px",
];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

// Font family array and dynamic family injection
const fontFamilyArr = [
  "Figtree, sans-serif",
  "sans-serif",
  "Arial",
  "Times New Roman",
  "Courier New",
  "Georgia",
  "Tahoma",
  "Verdana",
  "Helvetica",
];
const Font = Quill.import("attributors/style/font");
Font.whitelist = fontFamilyArr;
Quill.register(Font, true);

const injectCustomCSS = () => {
  const styleElement = document.createElement("style");
  const customCSS = `
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value=""]::before {
      content: "p";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value=""]::before {
      content: "p";
    }
    p {
      font-size: 20px; 
    }
   
  `;
  styleElement.innerHTML = customCSS;
  document.head.appendChild(styleElement);
};

const injectDynamicCSS = (sizes, families) => {
  const styleElement = document.createElement("style");
  let dynamicCSS = "";

  sizes.forEach((size) => {
    dynamicCSS += `
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="${size}"]::before {
        content: "${size}";
      }
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="${size}"]::before {
        content: "${size}";
      }
    `;
  });

  families.forEach((family) => {
    dynamicCSS += `
      .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="${family}"]::before {
        content: "${family}";
        font-family: "${family}";
      }
      .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="${family}"]::before {
        content: "${family}";
        font-family: "${family}";
      }
    `;
  });

  styleElement.innerHTML = dynamicCSS;
  document.head.appendChild(styleElement);
};

const RichText = ({
  initialValue,
  blockSrcText,
  type,
  block_id,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [disable, setdisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const [errText, seterrText] = useState("");
  const { setCourseOne } = useOutletContext();
  const [content, setContent] = useState(initialValue || "<p></p>");
  const { cid, mid } = useQueryParams();

  useEffect(() => {
    document.addEventListener("focusin", (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  useEffect(() => {
    Quill.register(Size, true);
    Quill.register(Font, true);
    injectDynamicCSS(fontSizeArr, fontFamilyArr);
    injectCustomCSS();
  }, []);

  let UserData = useSelector((state) => state.userLogin);

  const handleSave = async () => {
    if (!content.trim()) {
      setOpen(true);
      seterrText("Please Enter Title");
      setalertType("error");
      return;
    }
    if (content === "" || content === null || content === undefined) {
      setOpen(true);
      seterrText("Please Enter Title");
      setalertType("error");
    } else {
      if (content) {
        setdisable(true);
        let body = {
          user_id: UserData?.data?.user?.id,
          course: cid,
          order: blockModalOpen?.addBelow
            ? blockModalOpen?.currentBlockOrder + 1
            : updateOrder || 1,
          text: content,
          container: mid,
          type: type,
        };
        //create rich text block
        await POST(`${Constants.addBlock}/text?_id=${block_id}`, body)
          .then(async (response) => {
            setdisable(false);
            setOpen(true);
            setalertType("success");
            seterrText(`Block ${type === "edit" ? "Updated" : "Created"} Successfully`);
            setCourseOne((prev) => ({ ...prev, published: false }));
            if (type === "edit") {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const elIndex = prev.findIndex((el) => el._id === block_id);
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  updatedBlocks.splice(elIndex, 1, response?.data);
                  updatedBlocks.sort((a, b) => a?.order - b?.order);
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= response?.data?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, response?.data);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [response?.data, ...prev];
                  });
              }
            }

            setmodalOpen &&
              setmodalOpen((prev) => ({
                ...prev,
                modalOpen: false,
                currentBlockId: "",
              }));
            setblockModOpen(false);
          })
          .catch((error) => console.log(error));
      }
    }
  };

  return (
    <div className="richTextBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={initialValue}
      />
      <br />

      <ReactQuill
        value={content}
        onChange={(value) => setContent(value)}
        className="quill-editor"
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, ""] }],
            [{ size: fontSizeArr }],
            [{ font: fontFamilyArr }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            [{ align: [] }, { direction: "rtl" }],
            [{ color: [] }, { background: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
          ],
        }}
      />

      <br />

      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockSrcText}
        </Box>
      )}
      <br />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 14 }}>
        <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
          Save Block
        </ButtonMain>
      </Box>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default RichText;
