import { GET } from "../config/Api";
import { cloudfrontUrl } from "../config/BaseUrl";
import { Constants, s3Constants } from "../config/Constants";
import { Children } from "react";
import { audioFileExtensions, videoFileExtensions, fileExtensions } from "../config/Constants";
export const typeReturn = (type) => {
  switch (type) {
    case "button":
      return 2;
    case "comic":
      return 4;
    case "image":
      return 4;
    case "toggle_list":
      return 12;
    case "response":
      return 6;
    default:
      return 12;
  }
};

export const getDialogTitle = (type, block) => {
  switch (type) {
    case "toggle_list":
      return "Edit Toggle List";
    case "text":
      return "Edit Rich Text";
    case "divider":
      return "Edit Divider";
    case "image":
      return "Edit Image";
    case "button":
      return "Edit Button";
    case "link":
      return "Edit Web Bookmark";
    case "gif":
      return "Edit GIF";
    case "mcq":
      return "Edit MCQ";
    case "fill":
      return "Edit Fill In The Blanks";
    case "file":
      if (videoFileExtensions.includes(block?.provider?.url?.split(".").pop())) return "Edit Video";
      if (audioFileExtensions.includes(block?.provider?.url?.split(".").pop())) return "Edit Audio";
      if (fileExtensions.includes(block?.provider?.url?.split(".").pop())) return "Edit File";
      break;
    case "match":
      return "Edit Match The Following";
    case "scorm":
      return "Edit Scorm";
    case "list":
      return "Edit List";
    case "container":
      return "Edit Container";
    case "carousel_container":
      return "Edit Block Carousel";
    case "grid":
      return "Edit Grid";
    case "comic":
      return "Edit Comic Strip";
    case "embed":
      return "Edit Embed";
    case "discussion":
      return "Edit Discussion";
    case "copy":
      return "Edit Paste A Block";
    case "native-embed":
      return "Edit Native Embed";
    case "section_break":
      return "Edit Section Break";
    case "survey-monkey":
      return "Edit Survey Monkey";
    case "game":
      return "Edit Game";
    case "fs-form":
      return "Edit Fs Form";
    case "response":
      switch (block?.response_type) {
        case "text":
          return "Edit Text Response";
        case "audio":
          return "Edit Audio Recording";
        case "video":
          return "Edit Video Recording";
        case "file":
          return "Edit Upload File";
        default:
          break;
      }
      break;
    default:
      return `Edit ${type}`;
  }
};

export const toggleSelect = (item, selectedItems, setSelectedItems) => {
  const selectedIndex = selectedItems.indexOf(item);
  let newSelectedItems = [...selectedItems];

  // If the item is not already selected, add it to the selection
  if (selectedIndex === -1) {
    newSelectedItems = [...newSelectedItems, item];
  } else {
    // Otherwise, remove the item from the selection
    newSelectedItems.splice(selectedIndex, 1);
  }
  setSelectedItems(newSelectedItems);
};

export const useQueryParams = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params;
};

export const checkFileType = async (e) => {
  let fileType = e.target.files[0].name.split(".")[1];
  return fileType;
};

export const getCourseIdBySlug = async (slug) => {
  try {
    //get course id by slug
    let { data } = await GET(`${Constants.getCourseBySlug}/${slug}`);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getDimension = (e) => {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.onload = function () {
        const width = img.width;
        const height = img.height;
        resolve({ width, height });
      };
      img.src = e.target.result;
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getTermsName = (id, terms) => {
  const data = terms?.find((item) => item?._id === id);
  return data?.title;
};

export const getCourseTerm = (courses, termId) => {
  const courseTitles = [];
  for (const course of courses) {
    if (course?.terms === termId) {
      courseTitles.push(course);
    }
  }

  return courseTitles;
};

export const getColor = (termName) => {
  switch (termName) {
    case "Teachers":
      return "greenColor";
    case "K-12 Learners":
      return "blueColor";
    default:
      return "whiteColor";
  }
};

// To find the provided container and all of its children inside container blocks of a course.
export const findModule = (id, arr) => {
  for (const el of arr) {
    if (id === el._id) {
      return el; // Return the element when found
    } else if (el.children) {
      const result = findModule(id, el.children);
      if (result) return result; // Return the result if found in children
    }
  }
};

export function flattenArrayWithId(inputArray) {
  const flattenedArray = [];
  function recursiveFlatten(arr) {
    arr.forEach((item) => {
      flattenedArray.push(item._id);
      if (item.children && item.children.length > 0) {
        recursiveFlatten(item.children);
      }
    });
  }

  recursiveFlatten(inputArray);
  return flattenedArray;
}

export function flattenNavArr(inputArray) {
  const flattenedArray = [];
  function recursiveFlatten(arr) {
    arr.forEach((item) => {
      flattenedArray.push(item);
      if (item.children && item.children.length > 0) {
        recursiveFlatten(item.children);
      }
    });
  }

  recursiveFlatten(inputArray);
  return flattenedArray;
}

export function getParents(data, targetId) {
  const parentTitles = [];
  const visited = new Set(); // Track visited objects to avoid duplicates

  function findParent(id) {
    if (visited.has(id)) return; // Skip already visited object

    const currentObj = data.find((obj) => obj._id === id);
    if (!currentObj) return; // Object not found

    visited.add(currentObj._id); // Mark object as visited

    parentTitles.push(currentObj);
    if (currentObj.container) {
      findParent(currentObj.container); // Recursively find parent's parent
    }
  }

  findParent(targetId);
  return parentTitles.reverse(); // Return titles in order from outermost to innermost parent
}

export const Each = ({ render, of }) => {
  return Children.toArray(of.map((item, index) => render(item, index)));
};

export const convertToMillion = (number) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid input. Please provide a valid number.";
  }

  // Format the number with commas
  const formattedNumber = number.toLocaleString();

  return formattedNumber;
};
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const maskEmail = (email) => {
  // Split the email into local and domain parts
  const [localPart, domainPart] = email.split("@");

  // Ensure at least 3 characters in the local part before masking
  const maskedLocalPart =
    localPart.length >= 3
      ? localPart.charAt(0) + "*".repeat(localPart.length - 3) + localPart.slice(-2)
      : localPart;

  // Join the masked local part and the domain part to form the masked email
  const maskedEmail = `${maskedLocalPart}@${domainPart}`;

  return maskedEmail;
};
// https://chibuzz-uploads.s3.amazonaws.com/local/api_response_json/getPublicCourses.json
export const getS3ApiJsonKey = `${cloudfrontUrl}/${process.env.REACT_APP_SERVER_TYPE}/${s3Constants.S3_API_KEY}`;

export const executeScormFn = (userData, callAPIFn, scorm_data = {}) => {
  window.API_1484_11 = (function () {
    var data = scorm_data || {
      "cmi.core.student_id": userData?.id,
      "cmi.core.student_name": userData?.email,
      "cmi.core.lesson_location": "",
      "cmi.core.lesson_status": "not attempted",
      "cmi.suspend_data": "",
    };
    return {
      Initialize: function () {
        // alert('init000000000000');
        if (window.cudetails === undefined) {
          window.cudetails = {};
        }
        if (window.cudetails && window.cudetails.sdta && window.cudetails.sdta !== "") {
          data["cmi.suspend_data"] = window.cudetails.sdta;
        }

        return "true";
      },
      Commit: function () {
        // alert("commit");
        callAPIFn(data);
        console.log("Commit");
        return "true";
      },
      Terminate: function () {
        if (
          data["cmi.core.lesson_status"] !== "completed" &&
          data["cmi.core.lesson_status"] !== "passed"
        ) {
          window.cudetails.stime = data["cmi.core.session_time"];
          window.cudetails.susdt = data["cmi.suspend_data"];
          window.cudetails.atype = 1;
          // const json = JSON.stringify(window.cudetails);
          // console.log({ LMSFinish: window.cudetails });
          // callAPIFn(json);
        }
        // console.log({ Finish: window.cudetails });
        return "true";
      },
      GetValue: function (model) {
        // callAPIFn(data);
        // return;
        if (
          model === "cmi.learner_id" ||
          model === "cmi.interactions._count" ||
          model === "cmi.completion_status"
        ) {
          callAPIFn(data);
        }

        return data[model] || "";

        // if (
        //   window.cudetails.op !== undefined &&
        //   window.cudetails.op != 2 &&
        //   (data["cmi.core.lesson_status"] === "completed" ||
        //     data["cmi.core.lesson_status"] === "passed") &&
        //   window.cudetails !== undefined
        // ) {
        //   window.cudetails.ntmsg = model;
        //   window.nextbtn = true;
        //   window.cudetails.nttitle = "SetValue";
        //   window.cudetails.stime = data["cmi.core.session_time"];
        //   window.cudetails.susdt = "";
        //   window.cudetails.atype = 1;
        //   window.cudetails.op = 2;
        //   const json = JSON.stringify(window.cudetails);
        //   console.log({ getValue: window.cudetails });
        //   callAPIFn(json);
        //   if (
        //     window.cudetails &&
        //     window.cudetails.curObjIndex == window.cudetails.objlength &&
        //     window.cudetails.curNugIndex == window.cudetails.nuggetlength
        //   ) {
        //     window.cudetails.comp_date = 1;
        //     const json1 = JSON.stringify(window.cudetails);
        //     callAPIFn(json1);
        //   }
        // }
        // return data[model] || "";
      },
      SetValue: function (model, value) {
        data[model] = value;
        return "true";
      },
      GetLastError: function () {
        console.log("LASTERORR");
        return "0";
      },
      GetErrorString: function (errorCode) {
        console.log({ errorCode });
        return "No error";
      },
      GetDiagnostic: function (errorCode) {
        console.log({ errorCode });
        return "No error";
      },
    };
  })();
};

export const getCookie = (name) => {
  let cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");

    // Remove whitespace at the beginning of the cookie name and compare it to the given name
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export const embedTypes = [
  {
    name: "FS Form",
    value: "fs-form",
  },
  {
    name: "Survey-Monkey",
    value: "survey-monkey",
  },
  {
    name: "Typeform",
    value: "typeform",
  },
  {
    name: "Paperform",
    value: "paperform",
  },
  {
    name: "Youtube",
    value: "youtube",
  },
  {
    name: "Padlet",
    value: "padlet",
  },
  {
    name: "Genially",
    value: "genially",
  },
  {
    name: "FS Discussion",
    value: "fs-discussion",
  },
];

export const removeQueryParams = (url) => {
  const urlObj = new URL(url);
  return urlObj.origin + urlObj.pathname;
};

export const isLearnerView = (pathname) => {
  return !pathname.startsWith("/course/createblock");
};

export const getCohortDetailsByCohortId = async (cohortId) => {
  try {
    const response = await GET(`${Constants.getCohortDetailsByCohortId}/${cohortId}`);
    return response?.data?.cohort;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const checkIncompleteDetails = (
  name,
  email,
  title,
  org,
  about,
  city,
  sex,
  phone,
  dp,
  country,
  state,
  nativeLanguage,
  preferredLanguage,
) => {
  console.log({
    name,
    email,
    title,
    org,
    about,
    city,
    sex,
    phone,
    dp,
    country,
    state,
    nativeLanguage,
    preferredLanguage,
  });
  const missingDetails = [];
  email.includes(name) && missingDetails.push("Original Name");
  !title && missingDetails.push("Designation");
  !org && missingDetails.push("Organization");
  !about && missingDetails.push("Description");
  !city && missingDetails.push("City");
  !sex && missingDetails.push("Gender");
  !phone && missingDetails.push("Phone");
  !dp && missingDetails.push("Profile Photo");
  !country && missingDetails.push("Country");
  !state && missingDetails.push("State");
  !nativeLanguage && missingDetails.push("Native Language");
  !preferredLanguage && missingDetails.push("Preferred Language");

  return missingDetails;
};

export function countFieldsWithValues() {
  const args = [...arguments];
  console.log({ args: args.filter((el) => Boolean(el)).length });
  return args.filter((el) => Boolean(el)).length;
}
