import React, { useState } from "react";
import { Paper } from "@mui/material";
import "./nativeEmbedView.scss";
import BlockActions from "../BlockActions/BlockActions";
import { useSelector } from "react-redux";
import RenderIFrame from "./RenderEmbediframe";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useOutletContext } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const NativeEmbedView = ({ block, courseDetails, setmodalOpen, setblockModOpen }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const { completedModulesData } = useOutletContext() || {};

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const addUniqueId = (embededCode) => {
    if (block?.nativeEmbed?.platform === "fs-form") {
      if (embededCode?.endsWith("uniqueid=")) {
        embededCode += user?.email || "";
      } else {
        embededCode += "?uniqueid=" + user?.email;
      }
    } else if (block?.nativeEmbed?.platform === "survey-monkey") {
      embededCode =
        embededCode +
        "?uniqueId=" +
        user?.id +
        "&courseId=" +
        courseDetails?._id +
        "&blockId=" +
        block?._id +
        "&cohortInviteCode=" +
        completedModulesData?.cohortDetails?._id +
        "&positionLabel=" +
        block.nativeEmbed.position_label;
    }
    return embededCode;
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <Paper
          className={`
    embed-view ${isLoggedIn ? `postBgColor ${block?.theme}` : "defaultStyle"}
    `}
          style={{
            background: `${isLoggedIn && (block?.theme_type === "color" ? block?.color : "")}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={block?._id}
              block={block}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}
          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          <RenderIFrame
            platform={block?.nativeEmbed?.platform}
            url={addUniqueId(block?.nativeEmbed?.url)}
            title={block?.title}
            email={user?.email}
            blockHeight={block.size.height}
          />
          {block?.art?.m && <ArtView art={block?.art} />}
        </Paper>
      )}
    </>
  );
};

export default NativeEmbedView;
