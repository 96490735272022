import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Collapse, Typography, Button, Alert, Snackbar } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEntrySourceAction } from "../../../redux/action";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/IncorrectAnswer.svg";
import { ReactComponent as UnansweredblockIcon } from "../../../assets/Unanswered_block.svg";
import { useTheme } from "@mui/material/styles";

const IncorrectBlocks = ({
  incorrectBlocks,
  unansweredblocks,
  userDetails,
  completedModulesData,
}) => {
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const theme = useTheme();
  const [openSections, setOpenSections] = useState({
    incorrect: false,
    unanswered: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  //const { cid, mid } = useQueryParams();

  function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }
  const handleToggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleModuleOpen = async (item) => {
    try {
      // Async check for parentContainers' locked status
      const isParentLocked = item.containerDetails?.parentContainers
        ? await Promise.all(
            item.containerDetails?.parentContainers.map(async (parentContainerId) => {
              const parentModule = completedModulesData?.modules.find(
                (module) => module?.module_id === parentContainerId && module?.status === "locked",
              );
              return !!parentModule; // Return true if the parent is locked
            }),
          ).then((results) => results.every(Boolean)) // Check if all results are true (all parents are locked)
        : true;

      if (isParentLocked) {
        dispatch(setEntrySourceAction("menu_clicked"));
        setSelectedBlockId(item._id);
        navigate(`/course/${slug}/${item?.slug}?cid=${item?.course}&mid=${item?.container}`, {
          state: {
            userData: userDetails,
            blockId: item?._id,
          },
        });
      } else {
        setPopupOpen(true);
      }
    } catch (err) {
      console.error("Error in handleModuleOpen:", err);
    }
  };

  // const handleModuleOpen = (block) => {
  //   dispatch(setEntrySourceAction("menu_clicked"));
  //   navigate(`/course/${slug}/${block?.slug}?cid=${block?.course}&mid=${block?.container}`, {
  //     state: {
  //       userData: userDetails,
  //       blockId: block._id,
  //     },
  //   });
  //   setSelectedBlockId(block._id);
  // };

  const renderBlocks = (blocks) => (
    <Box>
      {blocks.map((block) => (
        <ListItemButton
          key={block._id}
          onClick={() => handleModuleOpen(block)}
          sx={{
            ml: 1,
            borderRadius: "6px",
            opacity: 0.95,
            color: selectedBlockId === block._id ? "#0051A2" : "white",
            bgcolor: selectedBlockId === block._id ? "#FFFFFF" : "transparent",
            borderLeft: "3px solid transparent",
            borderBottom: "1px solid #fff",
            pl: "14px",
            "&:hover": {
              bgcolor: "#4f89c3",
              color: "white",
            },
          }}
        >
          <ListItemText
            primary={block?.title === undefined ? block?.type : stripHTML(block.title)}
            sx={{
              ".MuiListItemText-primary": {
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      ))}
    </Box>
  );

  return (
    <Box>
      <>
        <ListItemButton onClick={() => handleToggleSection("incorrect")} sx={{ pl: "8px" }}>
          {openSections.incorrect ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
          <ListItemIcon sx={{ minWidth: "12px" }}>
            <IncorrectAnswerIcon style={{ fill: "#DE4209" }} />
          </ListItemIcon>
          <ListItemText
            primary="Incorrect Blocks"
            sx={{ color: "white", fontWeight: "bold", ml: 1 }}
          />

          <Button
            size="small"
            variant={"contained"}
            sx={{
              bgcolor: "#DE4209",
              border: "1px solid #fff",
              borderRadius: "50%",
              minWidth: "18px",
              minHeight: "18px",
              lineHeight: "0.70",
              padding: 0,
              mr: "2px",
              fontSize: "0.6rem",
            }}
          >
            {incorrectBlocks?.length}
          </Button>
        </ListItemButton>
        <Collapse in={openSections.incorrect} timeout="auto" unmountOnExit>
          {incorrectBlocks.length > 0 ? (
            renderBlocks(incorrectBlocks)
          ) : (
            <Typography sx={{ ml: 5, color: "white" }}>No incorrect blocks available.</Typography>
          )}
        </Collapse>

        {/* Unanswered Blocks Section */}
        <ListItemButton onClick={() => handleToggleSection("unanswered")} sx={{ pl: "8px" }}>
          {openSections.unanswered ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
          <ListItemIcon sx={{ minWidth: "19px" }}>
            <UnansweredblockIcon style={{ fill: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            primary="Unanswered Blocks"
            sx={{ color: "white", fontWeight: "bold", ml: 1 }}
          />
          <Button
            size="small"
            variant={"contained"}
            sx={{
              bgcolor: "#4f89c3",
              border: "1px solid #fff",
              borderRadius: "50%",
              minWidth: "18px",
              minHeight: "18px",
              lineHeight: "0.70",
              padding: 0,
              mr: "2px",
              fontSize: "0.6rem",
            }}
          >
            {unansweredblocks?.length}
          </Button>
        </ListItemButton>
        <Collapse in={openSections.unanswered} timeout="auto" unmountOnExit>
          {unansweredblocks.length > 0 ? (
            renderBlocks(unansweredblocks)
          ) : (
            <Typography sx={{ ml: 5, color: "white" }}>No unanswered blocks available.</Typography>
          )}
        </Collapse>
      </>
      {/* Popup for locked modules */}
      <Snackbar
        open={popupOpen}
        autoHideDuration={4000}
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            top: "60%",
            left: "19%",
            right: "60%",
          },
        }}
      >
        <Alert severity="warning" onClose={() => setPopupOpen(false)}>
          Please complete the prerequisite modules before accessing this.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IncorrectBlocks;
