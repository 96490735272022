import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import JoinCohort from "../../courses/JoinCohort/JoinCohort";
import "./landingPage.scss";
import LandingPageCard from "./LandingPageCard";
import { Each } from "../../../utils";

const EnrolledCourses = ({
  enrolledCourses,
  enrolledCourseLength,
  setPage,
  disableEnrolledLoadButton,
}) => {
  const [switchCohortData, setSwitchCohortData] = useState({
    open: false,
    course_id: "",
    course_slug: "",
    courseOne: {},
  });

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Box
        sx={{
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          margin: "2rem 0rem",
          position: "relative",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            left: 0,
            top: "-18px",
            background: "#ffffff",
            pr: 1,
            fontWeight: 600,
            color: "#555555",
          }}
        >
          Enrolled Courses
        </Typography>
      </Box>

      <div className="enrolled-container">
        <Each
          of={enrolledCourses}
          render={(item, index) => (
            <LandingPageCard
              isSwitchCohort={true}
              setSwitchCohortData={setSwitchCohortData}
              courseDetails={item?.lastrecord?.courseDetails[0]}
              cohortDetails={item?.lastrecord?.cohortDetails[0]}
              paymentId={item?.payment_id}
            />
          )}
        />
      </div>
      {enrolledCourseLength && (
        <Box sx={{ textAlign: "center", marginTop: "0.8rem" }}>
          <Button
            onClick={nextPage}
            sx={{ textTransform: "none" }}
            endIcon={<ArrowDropDownIcon />}
            disabled={disableEnrolledLoadButton}
          >
            Load More
          </Button>
        </Box>
      )}

      <JoinCohort
        modalOpen={switchCohortData?.open}
        modalClose={() => setSwitchCohortData((prev) => ({ ...prev, open: false }))}
        modalTitle={"Switch Cohort"}
        switchCohortData={switchCohortData}
        courseOne={switchCohortData?.courseOne}
        note="Please note that switching cohorts will remove your access in the current cohort."
        btnText="Switch Cohort"
      />
    </>
  );
};

export default EnrolledCourses;
