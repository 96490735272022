import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ReactComponent as EmailBox } from "../../../src/assets/emailBox.svg";
import { ReactComponent as QuestionMark } from "../../../src/assets/questionMark.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  Button,
  IconButton,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { USERDATA_UPDATE } from "../../redux/constants";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function EmailVerificationModal({ open, onClose }) {
  const [verifyToken, setToken] = useState(Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(900);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [verificationfield, setVerificationfield] = useState(false);
  const dispatch = useDispatch();
  let UserData = useSelector((state) => state.userLogin);
  let toastRef = useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const emailVerificationReasons = [
    {
      id: 1,
      title: "Security",
      reason:
        "Helps protect your account from unauthorized access by ensuring the email address belongs to you.",
      description:
        "It helps protect your account from unauthorized access. By verifying your email, the platform ensures that the email address belongs to you and not someone else.",
      color: "#1976d2",
    },
    {
      id: 2,
      title: "Password Recovery",
      reason: "Enables the platform to send a reset link if you forget your password.",
      description:
        "If you forget your password, the platform can send a reset link to your verified email, allowing you to regain access to your account.",
      color: "#1976d2",
    },
    {
      id: 3,
      title: "Communication",
      reason: "Ensures you receive important notifications, updates, and support messages.",
      description:
        " Verified emails ensure that important notifications, updates, and support messages reach you. This is crucial for staying informed about your learning progress and any changes or issues that may arise.",
      color: "#1976d2",
    },
    {
      id: 4,
      title: "Spam Prevention",
      reason: "Helps reduce spam and fake accounts.",
      description:
        "Verifying emails helps the platform reduce spam and fake accounts, creating a safer and more reliable learning environment for everyone.",
      color: "#1976d2",
    },
    {
      id: 5,
      title: "Compliance",
      reason: "Fulfills data protection regulations and policies.",
      description:
        "Some regulations and policies require platforms to verify user information to ensure compliance with data protection laws.",
      color: "#1976d2",
    },
  ];

  useEffect(() => {
    if (open) {
      setEmail(UserData?.data?.user?.reset_email);
      setTimer(900);
      setResendEnabled(false);
    }
  }, [open, UserData]);

  useEffect(() => {
    let countdown;
    if (timer > 0 && verificationfield) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, verificationfield]);

  const handleGetVerificationCode = async () => {
    try {
      const result = await GET(`${Constants.getVerifyEmailToken}`);

      if (result?.data?.success) {
        toastRef.current.showToastMessage(
          "Verification code sent to your email. Please check",
          "success",
        );
        setVerificationfield(true);
        setTimer(900);
        setResendEnabled(false);
      }
    } catch (error) {
      toastRef.current.showToastMessage("Failed to send verification code.", "error");
    }
  };

  const handleVerifiyCode = async () => {
    if (verifyToken.some((char) => char === "")) {
      toastRef.current.showToastMessage("Please enter all 6 digits of the code.", "error");
      return;
    }
    setLoading(true);
    try {
      let payload = {
        reset_email: email,
        verifyToken: verifyToken.join(""),
      };
      await POST(`${Constants.verifyEmail}`, payload);
      toastRef.current.showToastMessage("Email verified successfully", "success");
      const localUserData = JSON.parse(localStorage.getItem("userData"));
      localUserData.user.isEmailVerified = true;
      localStorage.setItem("userData", JSON.stringify(localUserData));
      dispatch({
        type: USERDATA_UPDATE,
        payload: localUserData,
      });
      setLoading(false);
      setTimeout(() => onClose(), 2000);
    } catch (error) {
      toastRef.current.showToastMessage(
        "Verification failed, please check the code and try again.",
        "error",
      );
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const handleTokenChange = (index, value) => {
    // Check if multiple characters are being pasted
    if (value.length > 1) {
      const updatedToken = [...verifyToken];
      // Distribute characters across inputs
      for (let i = 0; i < value.length && index + i < 6; i++) {
        updatedToken[index + i] = value[i];
      }
      setToken(updatedToken);

      // Automatically focus on the next empty field
      const nextEmptyIndex = index + value.length < 6 ? index + value.length : 5;
      document.getElementById(`input-${nextEmptyIndex}`).focus();
    } else if (/^\d$/.test(value) || value === "") {
      const updatedToken = [...verifyToken];
      updatedToken[index] = value;
      setToken(updatedToken);

      // Auto-focus to the next input if valid
      if (value && index < 5) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && verifyToken[index] === "") {
      if (index > 0) {
        const updatedToken = [...verifyToken];
        updatedToken[index - 1] = "";
        setToken(updatedToken);
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullScreen={isSmallScreen} maxWidth="lg">
      <ToastNotify ref={toastRef} duration={4000} />

      <DialogTitle
        sx={{
          textAlign: "left",
          fontSize: "1.25rem",
          fontWeight: "bold",
          position: "sticky",
          top: 0,
          zIndex: 10,
          background: "white",
        }}
      >
        Please verify your Email
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            border: "1px solid #c7c2c2",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ overflow: "visible" }}>
        <div
          style={{
            borderLeft: "4px solid #1976d2",
            background: "#e2e5e7a3",
            borderRadius: "2px",
            marginRight: 2,
            padding: 4,
            marginTop: 12,
          }}
        >
          <Typography variant="body2" sx={{ p: 1 }}>
            Note: Email verification is important for several reasons:
          </Typography>
        </div>

        <List>
          {emailVerificationReasons.map((reason) => (
            <ListItem key={reason.id}>
              <Typography variant="body2">
                {reason.id}. <strong style={{ color: reason.color }}>{reason.title}:</strong>{" "}
                {reason.reason}{" "}
                <NoMaxWidthTooltip title={reason.description}>
                  <QuestionMark style={{ cursor: "pointer" }} />
                </NoMaxWidthTooltip>
              </Typography>
            </ListItem>
          ))}
        </List>

        <Paper component="form" sx={{ p: "0px 0px", display: "flex", alignItems: "center", mb: 2 }}>
          <TextField
            sx={{ flex: 1 }}
            value={email}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <Divider sx={{ height: 36, m: 0.2 }} orientation="vertical" />
          <Button
            variant="contained"
            onClick={handleGetVerificationCode}
            disabled={verificationfield}
            size="large"
            sx={{ mt: 1 }}
          >
            Get Code
          </Button>
        </Paper>
      </DialogContent>
      {verificationfield && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "visible",
            p: 0,
          }}
        >
          <Box
            sx={{
              background: "#e2e5e7a3",
              p: 3,
              width: "90%",
              maxWidth: "430px",
              textAlign: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Typography variant="body1" sx={{ mb: 2 }}>
              Enter the verification code sent to your Mail
            </Typography>

            <EmailBox />
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 4 }}>
              {Array.from({ length: 6 }).map((_, index) => (
                <TextField
                  key={index}
                  id={`input-${index}`}
                  value={verifyToken[index]}
                  onChange={(e) => handleTokenChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedValue = e.clipboardData.getData("Text").trim();
                    handleTokenChange(index, pastedValue);
                  }}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                />
              ))}
            </Box>

            <Button
              color="success"
              variant="contained"
              onClick={handleVerifiyCode}
              sx={{ width: "100%" }}
            >
              {loading ? "Verifying..." : "Verify Email"}
            </Button>

            <Typography sx={{ mt: 1, mb: 2, color: "text.secondary" }}>
              Resend available in: {formatTime(timer)}
            </Typography>
            {resendEnabled && (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleGetVerificationCode}
                sx={{ width: "40%" }}
              >
                Resend Code
              </Button>
            )}
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default EmailVerificationModal;
