import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box } from "@mui/system";
import { Collapse, Alert, Snackbar } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParams } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setEntrySourceAction } from "../../../redux/action";
import { ReactComponent as RightIcon } from "../../../assets/rightIcon.svg";
import BlockIcon from "@mui/icons-material/Block";
import { useTheme } from "@mui/material/styles";

const MobileNavigateItem = ({ data, setOpen, userDetails, completedModulesData, setCourseNav }) => {
  const [open, setOpenState] = useState(false);
  const { slug } = useParams();
  const { cid, mid } = useQueryParams();
  let navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};
  const [isLocked, setIsLocked] = useState(false);
  const [parentLocked, setParentLocked] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleModuleOpen = (item) => {
    if (parentLocked) {
      isLoggedIn && dispatch(setEntrySourceAction("menu_clicked"));
      navigate(`/course/${slug}/${item?.slug}?cid=${cid}&mid=${item?._id}`, {
        state: {
          userData: userDetails,
        },
      });
      setCourseNav(false);
    } else {
      setPopupOpen(true);
    }
  };

  useEffect(() => {
    setParentLocked(
      data?.parentContainers
        ? data?.parentContainers?.every((parentContainerId) => {
            const parentModule = completedModulesData?.modules.find(
              (module) => module?.module_id === parentContainerId && module?.status === "locked",
            );
            return !!parentModule;
          })
        : true,
    );
    if (
      completedModulesData?.modules.some(
        (item) => item.module_id === data?._id && item.status === "locked",
      )
    ) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [data?._id, completedModulesData]);

  const handleToggle = () => {
    setOpenState(!open); // Toggle expand/collapse state
  };

  return (
    <Box pl={data.container ? 1 : 0}>
      <ListItemButton
        sx={{
          borderRadius: "6px",
          mt: 1,
          pl: "8px",
          opacity: 0.95,
          bgcolor: mid === data?._id ? "#0051A2" : "",

          color: mid === data?._id ? "white" : "",
          borderBottom: "1px solid #8c8585",
          "& >div>.MuiListItemText-secondary": {
            color: "#ffffffa1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "&:hover": {
            bgcolor: "#4f89c3",
            //color: "white",
            cursor: "pointer",
            "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
          },
          "&.Mui-selected": {
            color: "black",
            borderBottom: "1px solid #8c8585",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "auto" }} onClick={() => handleToggle()}>
          {data?.children?.length > 0 ? (
            open ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            <DescriptionOutlinedIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText sx={{ ml: 1 }} primary={data?.title} onClick={() => handleModuleOpen(data)} />

        {isLocked && (
          <ListItemIcon sx={{ minWidth: "10px" }}>
            <RightIcon
              fontSize="small"
              sx={{
                color: "green",
              }}
            />
          </ListItemIcon>
        )}

        {!parentLocked && (
          <ListItemIcon sx={{ minWidth: "10px" }}>
            <BlockIcon
              fontSize="small"
              sx={{
                fill: "#232323",
              }}
            />
          </ListItemIcon>
        )}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {data?.children?.map((child) => (
          <MobileNavigateItem
            key={child?._id}
            data={child}
            setOpen={setOpen}
            handleModuleOpen={handleModuleOpen}
            userDetails={userDetails}
            completedModulesData={completedModulesData}
          />
        ))}
      </Collapse>

      {/* Popup for locked modules */}
      <Snackbar
        open={popupOpen}
        autoHideDuration={4000}
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            top: "60%",
            left: "19%",
            right: "60%",
          },
        }}
      >
        <Alert severity="warning" onClose={() => setPopupOpen(false)}>
          Please complete the prerequisite modules before accessing this.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MobileNavigateItem;
