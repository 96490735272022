import React, { useState } from "react";
import { Button, Fade, Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import BookIcon from "../../../assets/Book-new.svg";
import { useNavigate } from "react-router-dom";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import background from "../../../assets/bg.jpg";

const LandingPageCard = ({
  courseDetails,
  cohortDetails,
  paymentId,
  isSwitchCohort,
  setSwitchCohortData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    navigate(`/course/${courseDetails?.slug}?cid=${courseDetails?._id}`);
  };

  return (
    <Grid
      container
      alignItems="start"
      style={{
        // backgroundColor: "#ebedf6",
        padding: "0.5rem",
        // backgroundColor: "rgb(238 239 241)",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "5px",
        boxShadow: "0px 0px 1px 1px #e5dfdf",
      }}
    >
      <Grid item xs={5} lg={3} sm={3} md={3} sx={{ p: 1, height: "100%" }}>
        {courseDetails?.image?.l ? (
          <img
            src={
              courseDetails?.image?.l?.includes(cloudfrontUrl)
                ? courseDetails?.image?.l
                : cloudfrontUrl + "/" + courseDetails?.image?.l
            }
            alt={courseDetails?.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              aspectRatio: 1 / 1,
              borderRadius: "5px",
            }}
          />
        ) : (
          <img
            src={BookIcon}
            alt={courseDetails?.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
              aspectRatio: 1 / 1,
              borderRadius: "5px",
              background: "#dda5a5",
            }}
          />
        )}
      </Grid>
      <Grid
        item
        xs={7}
        lg={9}
        sm={9}
        md={9}
        sx={{ p: 1 }}
        display={"flex"}
        alignItems={"start"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"100%"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              lineHeight: "25px",
              fontSize: "1.1rem",
              textTransform: "capitalize",
            }}
          >
            {courseDetails?.title?.length > 100
              ? courseDetails?.title?.substring(0, 100) + "..."
              : courseDetails?.title}
          </Typography>
          {isSwitchCohort && (
            <Tooltip title="Menu">
              <MoreVertIcon sx={{ cursor: "pointer" }} onClick={handleOpenMenu} />
            </Tooltip>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div></div>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "120px",
              lineHeight: 0,
              backgroundColor: "#1266d2",
            }}
            startIcon={isSwitchCohort ? <PlayArrowIcon /> : <DownloadIcon />}
            onClick={handleClick}
          >
            {isSwitchCohort ? "Resume" : "Certificate"}
          </Button>
        </div>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setSwitchCohortData((prev) => ({
              ...prev,
              open: true,
              course_id: courseDetails?._id,
              course_slug: courseDetails?.slug,
              courseOne: courseDetails,
            }));
            handleCloseMenu();
          }}
        >
          Switch Cohort
        </MenuItem>
        {cohortDetails?.isCourseDownloadEnabled && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `/course/download/${courseDetails?._id}`,
                "_blank",
                "noopener,noreferrer",
              );
              handleCloseMenu();
            }}
          >
            Download
          </MenuItem>
        )}
        {paymentId && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `/receipt/${paymentId}`,
                `Payment Receipt-${paymentId}`,
                "width=700,height=800,directories=yes,dependent=yes,channelmode=yes,scrollbars=yes",
              );
              handleCloseMenu();
            }}
          >
            View Payment Details
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};

export default LandingPageCard;
