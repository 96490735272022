import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import "../AddContainer/AddContainer.scss";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const AddSectionBreak = ({
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [title, setTitle] = useState(block?.title || "");
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertType, setalertType] = useState("error");
  const [checked, setChecked] = useState(false);
  const [errText, seterrText] = useState("");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleSubmit = async () => {
    if (title === "") {
      setOpen(true);
      seterrText("Please Enter Title");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        title: title,
        bound: 260,
        type: type,
      };
      setDisable(true);
      //submit container block
      await POST(`${Constants.addBlock}/section_break?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setOpen(true);
          setalertType("success");
          seterrText(`Block ${type ? "Updated" : "Created"} Successfully`);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div className="containerBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />

      <br />
      <InputBox placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      {checked && (
        <Typography variant="body1">
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Typography>
      )}
      <br />

      {checked && (
        <Typography variant="body1">
          <strong>Source Text:</strong> {block?.srcText}
        </Typography>
      )}
      <br />
      <ButtonMain className="buttonGreen" onClick={handleSubmit} disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddSectionBreak;
