import React from "react";
import DownloadCourseView from "../../components/courses/DownloadCourseView/DownloadCourseView";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";

const DownloadCourseLayout = () => {
  return (
    <HeaderContainer>
      <DownloadCourseView />
    </HeaderContainer>
  );
};

export default DownloadCourseLayout;
