import { Grid, TextField } from "@mui/material";
import React from "react";
import ButtonMain from "../ButtonMain";
import ImageUploader from "../Image/ImageUploader";
const CertificatePage = ({
  certificateData,
  setCertificateData,
  handleSubmit,
  disable,
  toastRef,
  previewCertificateImage,
  setPreviewCertificateImage,
  stateData,
}) => {
  const handleBackgroundImgChange = async (image) => {
    setCertificateData({
      ...certificateData,
      backgroundImage: image,
    });
    setPreviewCertificateImage({
      ...previewCertificateImage,
      background: URL.createObjectURL(image),
    });
  };

  const handleSignatureChange = async (image) => {
    setCertificateData({
      ...certificateData,
      signatureImage: image,
    });
    setPreviewCertificateImage({
      ...previewCertificateImage,
      signature: URL.createObjectURL(image),
    });
  };
  const handleLogoChange = async (image) => {
    setCertificateData({
      ...certificateData,
      logo: image,
    });
    setPreviewCertificateImage({
      ...previewCertificateImage,
      logo: URL.createObjectURL(image),
    });
  };

  const handleFormSubmit = async () => {
    await handleSubmit();

    // Clear the preview images after successful submit
    setPreviewCertificateImage({
      background: null,
      signature: null,
      logo: null,
    });
  };

  return (
    <div>
      <Grid container p={2} spacing={2} sx={{ paddingRight: 0 }}>
        <Grid item className="fullwidth" md={12}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Configuration Title"
            label="Configuration Title"
            value={certificateData.title}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                title: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" md={12}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Institution Name"
            label="Institution Name"
            value={certificateData.name}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" md={12}>
          <TextField
            placeholder="Describe about the institution"
            multiline
            fullWidth
            label="Describe about the institution"
            rows={4}
            variant="outlined"
            className="about_text"
            value={certificateData.desc}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                desc: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Certificate Text 1 (This certificate is awarded to...)"
            label="Certificate Text 1"
            value={certificateData.text1}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                text1: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Certificate Text 2 (for successfully completing the course...)"
            label="Certificate Text 2"
            value={certificateData.text2}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                text2: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Enter Signatory Name."
            label="Signatory Name"
            value={certificateData.signatoryName}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                signatoryName: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Enter Signatory Designation"
            label="Signatory Designation"
            value={certificateData.signatoryDesignation}
            onChange={(e) => {
              setCertificateData({
                ...certificateData,
                signatoryDesignation: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={12} sx={{ marginTop: "10px" }}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={4} md={4} position="relative">
          <ImageUploader
            onCropped={handleBackgroundImgChange}
            initialImage={previewCertificateImage?.background}
            circularCrop
            title={"Choose Certificate Background Image (1519 X 1092) or a square image"}
            className={"cert-fileUpload"}
            accept="image/*"
            targetWidth={1519}
            targetHeight={1092}
            icon={true}
            modaltype="certificate"
          />

          {/* {previewCertificateImage.background ? (
            <>
              <img
                src={previewCertificateImage.background}
                alt="Preview"
                style={{ width: "100%", height: "250px" }}
              />
              <ButtonMain
                className={"update_cert_button"}
                onClick={(e) => {
                  setPreviewCertificateImage({
                    ...previewCertificateImage,
                    background: null,
                  });
                }}
              >
                {" "}
                Change Photo
              </ButtonMain>
            </>
          ) : (
            <CustomInputFile
              title={
                "Choose Certificate Background Image (1519 X 1092) or a square image in PNG format"
              }
              icon={true}
              className="cert-fileUpload"
              onChange={handleBackgroundImgChange}
              accept="image/*"
            />
          )} */}
        </Grid>
        <Grid item xs={12} sm={4} md={4} position="relative">
          {/* {previewCertificateImage.signature ? (
            <>
              <img
                src={previewCertificateImage.signature}
                alt="Preview"
                style={{ width: "100%", height: "250px" }}
              />
              <ButtonMain
                className={"update_cert_button"}
                onClick={(e) => {
                  setPreviewCertificateImage({
                    ...previewCertificateImage,
                    signature: null,
                  });
                }}
              >
                {" "}
                Change Photo
              </ButtonMain>
            </>
          ) : (
            <CustomInputFile
              title={"Choose Signature Image (282 X 148) or a square image in PNG format"}
              icon={true}
              className="cert-fileUpload"
              onChange={handleSignatureChange}
              accept="image/*"
            />
          )} */}
          <ImageUploader
            onCropped={handleSignatureChange}
            initialImage={previewCertificateImage?.signature}
            circularCrop
            title={"Choose Signature Image (282 X 148) or a square image"}
            className={"cert-fileUpload"}
            accept="image/*"
            targetWidth={282}
            targetHeight={148}
            icon={true}
            modaltype="certificate"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} position="relative">
          {/* {previewCertificateImage.logo ? (
            <>
              <img
                src={previewCertificateImage.logo}
                alt="Preview"
                style={{ width: "100%", height: "250px" }}
              />
              <ButtonMain
                className={"update_cert_button"}
                onClick={(e) => {
                  setPreviewCertificateImage({
                    ...previewCertificateImage,
                    logo: null,
                  });
                }}
              >
                {" "}
                Change Photo
              </ButtonMain>
            </>
          ) : (
            <CustomInputFile
              title={"Choose Institute Logo (195 X 177) or a square image in PNG format"}
              icon={true}
              className="cert-fileUpload"
              onChange={handleLogoChange}
              accept="image/*"
            />
          )} */}

          <ImageUploader
            onCropped={handleLogoChange}
            initialImage={previewCertificateImage?.logo}
            circularCrop
            title={"Choose Institute Logo (195 X 177) or a square image in PNG format"}
            className={"cert-fileUpload"}
            accept="image/*"
            targetWidth={195}
            targetHeight={177}
            icon={true}
            modaltype="certificate"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ float: "right", mt: 4 }}>
          <ButtonMain className={"view_cert_button"} onClick={handleFormSubmit} disabled={disable}>
            {stateData?._id ? "Update" : "Create"} Certificate
          </ButtonMain>
        </Grid>
      </Grid>
    </div>
  );
};

export default CertificatePage;
