import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkIncompleteDetails } from "../utils";

const useIncompleteDetails = () => {
  const [missingDetails, setMissingDetails] = useState([]);

  let UserData = useSelector((state) => state.userLogin);

  useEffect(() => {
    const detailsToAdd = checkIncompleteDetails(
      UserData?.data?.user?.name,
      UserData?.data?.user?.email,
      UserData?.data?.user?.job?.title,
      UserData?.data?.user?.job?.org,
      UserData?.data?.user?.about,
      UserData?.data?.user?.city,
      UserData?.data?.user?.sex,
      UserData?.data?.user?.phone,
      UserData?.data?.user?.dp,
      UserData?.data?.user?.country,
      UserData?.data?.user?.state,
      UserData?.data?.user?.nativeLanguage,
      UserData?.data?.user?.preferredLanguage,
    );
    setMissingDetails(detailsToAdd);
  }, [UserData]);

  return [missingDetails];
};

export default useIncompleteDetails;
