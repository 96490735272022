import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ArtView from "../ArtView/ArtView";
import BlockActions from "../BlockActions/BlockActions";
import "./TextView.scss";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";
import { useOutletContext } from "react-router-dom";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const TextViews = ({ block, art, courseDetails, setmodalOpen, setblockModOpen }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [showActions, setShowActions] = useState(false);

  const themes = useTheme();
  const contentRef = useRef(null);
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const { courseOne, completedModulesData } = useOutletContext() || {};
  let moduleDetails = useSelector((state) => state.savedModuleDetail);

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const triggerUserEvent = (userResponse = "") => {
    if (user?.email) {
      const eventData = new clickEvent();
      eventData.event = "link_inside_content_clicked";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails?.data?.id || "";
      eventData.block_id = block?._id;
      eventData.course_id = courseOne?._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle || "";
      eventData.link_href = userResponse;
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  useEffect(() => {
    const handleLinkClick = (event) => {
      // Check if the clicked element is a link and user is logged in
      if (event.target.tagName === "A" && isLoggedIn) {
        const linkContent = event.target.href || event.target.innerText;
        triggerUserEvent("link_inside_content_clicked", linkContent);
      }
    };

    const contentElement = contentRef.current;

    if (contentElement) {
      // Add click event listener to the content container
      contentElement.addEventListener("click", handleLinkClick);
    }

    return () => {
      if (contentElement) {
        // Clean up the event listener on component unmount
        contentElement.removeEventListener("click", handleLinkClick);
      }
    };
  }, [isLoggedIn, courseOne?._id, moduleDetails?.data?.id]);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block.is_hidden ? (
        ""
      ) : (
        <div
          id="text-view"
          className={` 
      
     ${block?.theme ? block?.theme : "postBgColor"}
   `}
          style={{
            background: `${block?.theme_type === "color" ? block?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              block={block}
              courseDetails={courseDetails}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          <div
            ref={contentRef}
            className={`text_view_content ${art?.m ? "text_with_art" : ""}`}
            dangerouslySetInnerHTML={{ __html: block?.text }}
          ></div>
          {art?.m && <ArtView art={art} />}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}
        </div>
      )}
    </>
  );
};

export default TextViews;
