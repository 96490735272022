import React, { useRef, useState } from "react";
import { InputBox } from "../../../../InputBox/InputBox";
import { Constants } from "../../../../../config/Constants";
import ButtonMain from "../../../../ButtonMain";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import { useOutletContext } from "react-router-dom";
import { POST } from "../../../../../config/Api";
import { removeQueryParams, useQueryParams } from "../../../../../utils";

const AddGame = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [disable, setDisable] = useState(false);
  const [inputValue, setInputValue] = useState({
    url: block?.url || "",
  });
  const toastRef = useRef();
  const { setCourseOne } = useOutletContext();
  const { cid, mid } = useQueryParams();

  const handleSave = async () => {
    if (inputValue.url.trim() === "") {
      toastRef.current.showToastMessage("Please Enter url.", "error");
    } else {
      let payload = {
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        type: type,
        url: removeQueryParams(inputValue.url),
      };
      setDisable(true);
      await POST(`${Constants.addBlock}/game?_id=${block?._id}`, payload)
        .then((response) => {
          setDisable(false);
          toastRef.current.showToastMessage("Block Created Successfully.", "success");
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <ToastNotify ref={toastRef} duration={3000} />

      <InputBox
        placeholder="Enter URL"
        name="url"
        value={inputValue.url}
        onChange={(e) => setInputValue({ ...inputValue, url: e.target.value })}
      />

      <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
        Save Block
      </ButtonMain>
    </div>
  );
};

export default AddGame;
