import { Divider, Chip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const SectionBreakView = ({ container, courseDetails, setmodalOpen, setblockModOpen, block }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm"));
  const [showActions, setShowActions] = useState(false);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {isModerator === -1 && block?.is_hidden ? (
        ""
      ) : (
        <div
          style={{
            background: `${container?.theme_type === "color" ? container?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={container?._id}
              block={container}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          <Divider>
            <Chip label={container?.title} size="large" />
          </Divider>
        </div>
      )}
    </>
  );
};

export default SectionBreakView;
