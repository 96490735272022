import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import { Box, Typography, IconButton, Tabs, Tab, Tooltip } from "@mui/material";
import NavigateItem from "./NavigateItem";
import "./Navigate.scss";
import { ReactComponent as BlueCourseNavigateIcon } from "../../../assets/courseNavigation.svg";
import { ReactComponent as CourseNavigateIcon } from "../../../assets/CourseNavigate.svg";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/IncorrectAnswer.svg";
//import { ReactComponent as UnAnsweredBlock } from "../../../assets/WrongAnswer.svg";
import { ReactComponent as GoBackIcon } from "../../../assets/goBack.svg";
//import SectionModal from "../CourseLayout/SectionModal";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
import IncorrectBlocks from "./IncorrectBlocks";
import HomeIcon from "@mui/icons-material/Home";

export default function Navigate({
  modDetails,
  setCourseNav,
  open,
  setOpen,
  courseId,
  userDetails,
  courseOne,
  completedModulesData,
  blockActivity,
  navTab,
  courseBlock,
}) {
  const isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  const [selectedTab, setSelectedTab] = useState(0);
  const [navContent, setnavContent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [unAnsweredBlocks, setUnansweredBlocks] = useState([]);
  const { cid, mid } = useQueryParams();

  useEffect(() => {
    // Set the selected tab based on navTab if it is provided
    if (navTab !== undefined && navTab !== null) {
      setSelectedTab(navTab);
    }
  }, [navTab]);

  const handleHomeClick = () => {
    setnavContent(false);
    navigate(`/course/${slug}?cid=${cid}`, {
      state: {
        userData: userDetails,
      },
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleIncorrectAnswers = async () => {
    setLoading(true);
    const module = courseBlock ? null : mid;
    try {
      const res = await POST(`${Constants.checkIncorrectBlocks}/${cid}/${module}`);
      if (res?.data?.success) {
        setIncorrectAnswers([]);
        setUnansweredBlocks([]);
        setLoading(false);
      } else {
        if (res?.data?.incorrectBlocks?.length > 0 || res?.data?.unAnsweredBlocks?.length > 0) {
          setIncorrectAnswers(res.data.incorrectBlocks);
          setUnansweredBlocks(res.data.unAnsweredBlocks);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching incorrect blocks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("/course/createblock")) {
      setCourseNav(true);
      setnavContent(true);
      setSelectedTab(1);
    }
  }, []);
  useEffect(() => {
    isLoggedIn && handleIncorrectAnswers();
  }, [blockActivity, navTab]);

  return (
    <>
      {isLoggedIn && completedModulesData?.status === "enrolled" && open && (
        <Box className="navigate" sx={{ display: "flex", mr: 1 }}>
          <Box
            display="flex"
            sx={{
              width: "100%",
              height: "80vh",
              position: "sticky",
              top: 120,
              bgcolor: "#365082",
              p: "1px",
            }}
          >
            <Tabs
              orientation="vertical"
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="none"
              aria-label="vertical navigation tabs"
              sx={{
                ".MuiTab-root": {
                  minWidth: "auto",
                  minHeight: "42px",
                  padding: "7px",
                  background: "#c2c5c980",
                  border: "1.5px solid #fff",
                  //opacity: 1,
                  mr: "3px",
                  borderRadius: "0px 4px 4px 0px",
                },
                ".Mui-selected": {
                  color: "#DE4209",
                  border: "2px solid #fff",
                  boxShadow: "0px 3px 6px #00000061",
                  borderRadius: "0px 4px 4px 0px",
                  background: "#fff",
                  opacity: 2,
                  mr: 0,
                },
              }}
            >
              <Tooltip title="Course Home Page">
                <Tab
                  icon={
                    <HomeIcon
                      fontSize="large"
                      style={{
                        fontSize: "1.6rem",
                        fill: selectedTab === 0 ? "#1266d2" : "#fff",
                      }}
                    />
                  }
                  onClick={handleHomeClick}
                  aria-label="Course Modules"
                  sx={{
                    padding: 0,
                  }}
                />
              </Tooltip>

              <Tooltip title="Course Navigation">
                <Tab
                  icon={
                    selectedTab === 1 ? (
                      <BlueCourseNavigateIcon fontSize="medium" />
                    ) : (
                      <CourseNavigateIcon fontSize="medium" />
                    )
                  }
                  onClick={() => setnavContent(true)}
                  aria-label="Course Navigation"
                  sx={{
                    padding: 0,
                    mt: 1,
                    fill: "#fff",
                    "&.Mui-selected": {
                      fill: "#1266d2",
                    },
                  }}
                />
              </Tooltip>
              {navTab && (
                <Tooltip title="Incorrect & unanswered modules">
                  <Tab
                    onClick={() => {
                      setnavContent(true);
                      handleIncorrectAnswers();
                    }}
                    icon={
                      <IncorrectAnswerIcon
                        fontSize="large"
                        style={{ width: "18.75", height: "22" }}
                      />
                    }
                    aria-label="Wrong Answers"
                    sx={{
                      padding: 0,
                      mt: 1,
                      fill: "#fff",
                      "&.Mui-selected": {
                        fill: "#DE4209",
                      },
                    }}
                  />
                </Tooltip>
              )}
            </Tabs>
          </Box>
          {navContent && (
            <Box>
              <Box
                sx={{
                  position: "sticky",
                  top: 120,
                  zIndex: 1,
                  flexGrow: 1,
                  height: "80vh",
                  overflow: "auto",
                  bgcolor: "#243A64",
                }}
              >
                <List
                  sx={{
                    width: { sm: "250px" },
                    borderRadius: "3px",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      sx={{
                        bgcolor: "#365082",
                        color: "white",
                        borderBottom: "1px solid #ccc",
                        p: 1,
                      }}
                    >
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography component={"h4"} variant="body1" fontWeight={600}>
                          {selectedTab === 1 && "Course Navigation"}
                          {selectedTab === 2 && "Unanswered modules"}
                        </Typography>
                        <IconButton onClick={() => setnavContent(false)}>
                          <GoBackIcon fontSize="small" fill="#fff" height={"17px"} width={"21px"} />
                        </IconButton>
                      </Box>
                    </ListSubheader>
                  }
                >
                  {selectedTab === 1 && (
                    <Box sx={{ height: "71vh" }}>
                      {modDetails?.map((item, index) => (
                        <NavigateItem
                          data={item}
                          setOpen={setOpen}
                          courseId={courseId}
                          userDetails={userDetails}
                          completedModulesData={completedModulesData}
                        />
                      ))}
                    </Box>
                  )}

                  {selectedTab === 2 && (
                    <Box sx={{ height: "71vh" }}>
                      <IncorrectBlocks
                        incorrectBlocks={incorrectAnswers}
                        unansweredblocks={unAnsweredBlocks}
                        // setOpen={setOpen}
                        loading={loading}
                        // courseId={courseId}
                        userDetails={userDetails}
                        completedModulesData={completedModulesData}
                      />
                    </Box>
                  )}
                </List>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
