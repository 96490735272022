import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Collapse, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEntrySourceAction } from "../../../redux/action";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/IncorrectAnswer.svg";
import { ReactComponent as UnansweredblockIcon } from "../../../assets/Unanswered_block.svg";

const MobileIncorrectBlocks = ({
  incorrectBlocks,
  unansweredblocks,
  userDetails,
  setOpen,
  setCourseNav,
}) => {
  // const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [openSections, setOpenSections] = useState({
    incorrect: false,
    unanswered: false,
  });
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  const handleToggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleModuleOpen = (block) => {
    dispatch(setEntrySourceAction("menu_clicked"));
    navigate(`/course/${slug}/${block?.slug}?cid=${block?.course}&mid=${block?.container}`, {
      state: {
        userData: userDetails,
        blockId: block._id,
      },
    });
    setCourseNav(false);
    //setSelectedBlockId(block._id);
  };

  const renderBlocks = (blocks) => (
    <Box>
      {blocks.map((block) => (
        <ListItemButton
          key={block._id}
          onClick={() => handleModuleOpen(block)}
          sx={{
            ml: 1,
            borderRadius: "6px",
            opacity: 0.95,
            //color: selectedBlockId === block._id ? "#0051A2" : " white",
            //bgcolor: selectedBlockId === block._id ? "white" : "",
            mt: 1,
            borderBottom: "1px solid #8c8585",
            "& >div>.MuiListItemText-secondary": {
              color: "#ffffffa1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
            "&:hover": {
              bgcolor: "#4f89c3",
              //color: "white",
              cursor: "pointer",
              "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
            },
            "&.Mui-selected": {
              color: "black",
              borderBottom: "1px solid #8c8585",
            },
          }}
        >
          <ListItemText
            primary={block.title === undefined ? block?.type : stripHTML(block.title)}
            sx={{
              ".MuiListItemText-primary": {
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: "16px",
                ml: 1,
              },
            }}
          />
        </ListItemButton>
      ))}
    </Box>
  );
  return (
    <Box>
      <ListItemButton onClick={() => handleToggleSection("incorrect")}>
        {openSections.incorrect ? <ExpandLess /> : <ExpandMore />}
        <ListItemIcon sx={{ minWidth: "16px" }}>
          <IncorrectAnswerIcon style={{ fill: "#DE4209" }} />
        </ListItemIcon>
        <ListItemText primary="Incorrect Blocks" sx={{ fontWeight: "bold", ml: 1 }} />

        <Button
          size="small"
          variant={"contained"}
          sx={{
            bgcolor: "#DE4209",
            border: "1px solid #fff",
            borderRadius: "50%",
            minWidth: "18px",
            minHeight: "18px",
            lineHeight: "0.70",
            padding: 0,
            mr: "2px",
            fontSize: "0.6rem",
          }}
        >
          {incorrectBlocks?.length}
        </Button>
      </ListItemButton>
      <Collapse in={openSections.incorrect} timeout="auto" unmountOnExit>
        {incorrectBlocks.length > 0 ? (
          renderBlocks(incorrectBlocks)
        ) : (
          <Typography sx={{ ml: 5, mt: 1, mb: 1 }}>No incorrect blocks available.</Typography>
        )}
      </Collapse>

      {/* Unanswered Blocks Section */}
      <ListItemButton onClick={() => handleToggleSection("unanswered")} sx={{ mt: 1 }}>
        {openSections?.unanswered ? <ExpandLess /> : <ExpandMore />}
        <ListItemIcon sx={{ minWidth: "24px" }}>
          <UnansweredblockIcon fill="#4f89c3" />
        </ListItemIcon>
        <ListItemText primary="Unanswered Blocks" sx={{ fontWeight: "bold", ml: 1 }} />
        <Button
          size="small"
          variant={"contained"}
          sx={{
            bgcolor: "#4f89c3",
            border: "1px solid #fff",
            borderRadius: "50%",
            minWidth: "18px",
            minHeight: "18px",
            lineHeight: "0.70",
            padding: 0,
            mr: "2px",
            fontSize: "0.6rem",
          }}
        >
          {unansweredblocks?.length}
        </Button>
      </ListItemButton>
      <Collapse in={openSections.unanswered} timeout="auto" unmountOnExit>
        {unansweredblocks.length > 0 ? (
          renderBlocks(unansweredblocks)
        ) : (
          <Typography sx={{ ml: 5, mt: 1, mb: 1 }}>No unanswered blocks available.</Typography>
        )}
      </Collapse>
    </Box>
  );
};

export default MobileIncorrectBlocks;
