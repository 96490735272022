import React from "react";
import { Grid, TextField } from "@mui/material";
import ButtonMain from "../ButtonMain";
import ImageUploader from "../Image/ImageUploader";

const SignatoryPage = ({
  signatoryData,
  setSignatoryData,
  handleSubmit,
  disable,
  toastRef,
  previewSignatoryImage,
  setPreviewSignatoryImage,
  stateData,
}) => {
  const handleSignatureChange = async (image) => {
    setSignatoryData({
      ...signatoryData,
      signatureImage: image,
    });
    setPreviewSignatoryImage({
      ...previewSignatoryImage,
      signature: URL.createObjectURL(image),
    });
  };
  const handleLogoChange = async (image) => {
    setSignatoryData({
      ...signatoryData,
      logo: image,
    });
    setPreviewSignatoryImage({
      ...previewSignatoryImage,
      logo: URL.createObjectURL(image),
    });
  };
  return (
    <div>
      <Grid container p={2} spacing={2} sx={{ paddingRight: 0 }}>
        <Grid item className="fullwidth" md={12}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Configuration Title"
            label="Configuration Title"
            value={signatoryData.title}
            onChange={(e) => {
              setSignatoryData({
                ...signatoryData,
                title: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" md={12}>
          <TextField
            fullWidth
            autoComplete={"false"}
            placeholder="Institution Name"
            label="Institution Name"
            value={signatoryData.name}
            onChange={(e) => {
              setSignatoryData({
                ...signatoryData,
                name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" md={12}>
          <TextField
            placeholder="Describe about the institution"
            multiline
            fullWidth
            label="Describe about the institution"
            rows={4}
            variant="outlined"
            className="about_text"
            value={signatoryData.desc}
            onChange={(e) => {
              setSignatoryData({
                ...signatoryData,
                desc: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            placeholder="Enter Signatory Name"
            fullWidth
            label="Signatory Name"
            variant="outlined"
            className="about_text"
            value={signatoryData.signatoryName}
            onChange={(e) => {
              setSignatoryData({
                ...signatoryData,
                signatoryName: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="fullwidth" sm={6}>
          <TextField
            placeholder="Enter Signatory Designation"
            fullWidth
            label="Signatory Designation"
            variant="outlined"
            className="about_text"
            value={signatoryData.signatoryDesignation}
            onChange={(e) => {
              setSignatoryData({
                ...signatoryData,
                signatoryDesignation: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} position="relative">
          <ImageUploader
            onCropped={handleSignatureChange}
            initialImage={previewSignatoryImage?.signature}
            circularCrop
            title={"Choose Signature Image (282 X 148) or a square image"}
            className={"cert-fileUpload"}
            accept="image/*"
            targetWidth={282}
            targetHeight={148}
            icon={true}
            modaltype="certificate"
          />

          {/* {previewSignatoryImage.signature ? (
            <>
              <img
                src={previewSignatoryImage.signature}
                alt="Preview"
                style={{ width: "100%", height: "250px" }}
              />
              <ButtonMain
                className={"update_cert_button"}
                onClick={(e) => {
                  setPreviewSignatoryImage({
                    ...previewSignatoryImage,
                    signature: null,
                  });
                }}
              >
                {" "}
                Change Photo
              </ButtonMain>
            </>
          ) : (
            <CustomInputFile
              title={"Choose Signature Image (282 X 148) or a square image in PNG format"}
              icon={true}
              className="cert-fileUpload"
              onChange={handleSignatureChange}
              accept="image/*"
            />
          )} */}
        </Grid>
        <Grid item xs={12} sm={4} md={4} position="relative">
          <ImageUploader
            onCropped={handleLogoChange}
            initialImage={previewSignatoryImage?.logo}
            circularCrop
            title={"Choose Institute Logo (195 X 177) or a square image"}
            className={"cert-fileUpload"}
            accept="image/*"
            targetWidth={195}
            targetHeight={177}
            icon={true}
            modaltype="certificate"
          />

          {/*           
          {previewSignatoryImage.logo ? (
            <>
              <img
                src={previewSignatoryImage.logo}
                alt="Preview"
                style={{ width: "100%", height: "250px" }}
              />
              <ButtonMain
                className={"update_cert_button"}
                onClick={(e) => {
                  setPreviewSignatoryImage({
                    ...previewSignatoryImage,
                    logo: null,
                  });
                }}
              >
                {" "}
                Change Photo
              </ButtonMain>
            </>
          ) : (
            <CustomInputFile
              title={"Choose Institute Logo (195 X 177) or a square image in PNG format"}
              icon={true}
              className="cert-fileUpload"
              onChange={handleLogoChange}
              accept="image/*"
            />
          )} */}
        </Grid>

        <Grid item xs={12}>
          <ButtonMain className={"view_cert_button"} onClick={handleSubmit} disabled={disable}>
            {stateData?._id ? "Update" : "Create"} Signatory
          </ButtonMain>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignatoryPage;
