import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import ButtonMain from "../../../ButtonMain";
import ArtView from "../ArtView/ArtView";
import { VideoRecord } from "../VideoView/VideoView";
import { UploadFile } from "../FileView/FileView";
import "./ResponseView.scss";
import { AudioRecording } from "../AudioView/AudioView";
import { isLearnerView, useQueryParams } from "../../../../utils";
import BlockActions from "../BlockActions/BlockActions";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { useLocation, useOutletContext } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LearnerProperties from "../../LearnerProperties";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const ResponseView = ({
  response,
  art,
  courseDetails,
  blockUserActivities,
  courseDetail,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  isHighlight,
  setisHighlight,
  handleBlockAnswer,
}) => {
  const [responseText, setResponseText] = useState("");
  const [disable, setDisable] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [loading, setloading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [blockActivity, setBlockActivity] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showActions, setShowActions] = useState(false);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const { cid } = useQueryParams();
  const toastRef = useRef();
  let location = useLocation();
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  let moduleDetails = useSelector((state) => state.savedModuleDetail);
  const { courseOne, completedModulesData, userDetails } = useOutletContext() || {};

  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisable(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item.block_id === response._id);
    setBlockActivity(data);
    setResponseText(data?.response_value || "");
    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [blockUserActivities]);

  const open = Boolean(anchorEl);
  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const handleResponseSubmit = async () => {
    if (isLoggedIn) {
      if (responseText.trim() === "" || responseText.trim() === null) {
        toastRef.current.showToastMessage("Please enter some text.", "error");
      } else {
        setDisable(true);
        setloading(true);
        try {
          let bodyParams = {
            user_id: user.id,
            course_id: cid,
            activity: "response",
            response_type: "text",
            response_value: responseText.trim(),
          };
          //submit text response answer
          await POST(`${Constants.saveAndUpdateUserActivities}/${response?._id}`, bodyParams);
          setisHighlight &&
            setisHighlight((prevState) => prevState.filter((item) => item !== response._id));
          handleBlockAnswer(response?._id);
          setcheckUserInputs(true);
          setDisable(false);
          setloading(false);
          triggerUserEvent(`${checkUserInputs ? "update" : "save"}`);
          toastRef.current.showToastMessage(
            "Your response has been submitted successfully.",
            "success",
          );
          //save user last activity
          await POST(
            `${Constants.userActivity}?activity=${
              checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
            }`,
            {
              user: user,
            },
          );
        } catch (error) {
          toastRef.current.showToastMessage("Something went wrong", "error");
          setloading(false);
        }
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = responseText.trim();
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <>
          <Box
            position={"relative"}
            //       className={` container-block response-block
            //     ${disableBlock ? "disableBlock" : ""}
            // ${isHighlight && response?.response_type === "text" && "highlightBlock"}
            //        ${isLoggedIn ? `postBgColor ${response?.theme}` : "defaultStyle"}
            //     `}
            //       style={{
            //         background: `${isLoggedIn && (response?.theme_type === "color" ? response?.color : "")}`,
            //       }}
            className={` container-block response-block ${isHighlight && "highlightBlock"} ${
              disableBlock ? "disableBlock" : ""
            }
         ${response?.theme ? response?.theme : "postBgColor"} 
      `}
            style={{
              background: `${response?.theme_type === "color" ? response?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
              <BlockActions
                blockId={response?._id}
                block={response}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}

            {isMobile && (
              <MenuIconWrapper onClick={handleMenuClick}>
                <MoreVertIcon fontSize="large" color="action" />
              </MenuIconWrapper>
            )}

            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}
            <p className="block-title">
              {response?.is_required && <span className="required">*</span>}
              {response?.title}
              {response.response_type === "audio" && (
                <AudioRecording
                  block={response}
                  blockUserActivities={blockUserActivities}
                  disableBlock={disableBlock}
                />
              )}
              {response.response_type === "video" && (
                <VideoRecord
                  block={response}
                  blockUserActivities={blockUserActivities}
                  disableBlock={disableBlock}
                />
              )}
              {response.response_type === "file" && (
                <>
                  <Typography mt={1}>{response?.text}</Typography>
                  <UploadFile
                    block={block}
                    block_id={response?._id}
                    blockUserActivities={blockUserActivities}
                    courseDetail={courseDetail}
                    courseDetails={courseDetails}
                    courseOne={courseOne}
                    disableBlock={disableBlock}
                  />
                </>
              )}
            </p>
            {response?.response_type === "text" && (
              <div className="text-description">{response.text}</div>
            )}
            {response?.response_type === "text" && (
              <>
                <textarea
                  placeholder="Write your thoughts/answer here..."
                  disabled={disable || disableBlock}
                  className="text-response entity-text"
                  value={responseText}
                  onChange={(e) => {
                    setResponseText(e.target.value);
                    // setisHighlight &&
                    //   setisHighlight((prevState) =>
                    //     prevState.filter((item) => item !== response._id),
                    //   );
                  }}
                ></textarea>{" "}
                <br />
                <ButtonMain
                  onClick={handleResponseSubmit}
                  disabled={disable || disableBlock || !isLoggedIn}
                  style={{ padding: `${loading ? "5px 25px" : ""}` }}
                >
                  {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
                </ButtonMain>
                {userDetails?.role === userRoles?.REVIEWER && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleBlockProperties}
                    sx={{ float: "right" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </>
            )}
            <ArtView art={art} />
            <ToastNotify ref={toastRef} duration={3000} />
          </Box>
          {userDetails?.role === userRoles?.REVIEWER && (
            <LearnerProperties
              anchorEl={anchorEl}
              open={open}
              handleClose={() => setAnchorEl(null)}
              obtainedMark={blockActivity?.credit}
              maxMark={block?.score || 0}
              blockType={block?.type}
              attempts={blockActivity?.NoOfAttempts}
              keywords={block?.keywords}
              scoreType={block?.typeOfBlock}
            />
          )}
          {/* {response?.response_type === "text" && (
        <>
          <textarea
            placeholder="Write your thoughts/answer here..."
            disabled={disableBlock}
            className={`text-response entity-text ${
              isLoggedIn ? "post-entity-text" : "pre-entity-text"
            }`}
            value={responseText}
            onChange={(e) => {
              setResponseText(e.target.value);
              setisHighlight &&
                setisHighlight((prevState) => prevState.filter((item) => item !== response._id));
            }}
          ></textarea>{" "}
          <br />
          <ButtonMain
            onClick={handleResponseSubmit}
            disabled={disable || disableBlock}
            style={{ padding: `${loading ? "5px 25px" : ""}` }}
          >
            {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
          </ButtonMain>
        </>
      )} */}
          {/* <ArtView art={art} /> */}
          <ToastNotify ref={toastRef} duration={3000} />
        </>
      )}
    </>
  );
};

export default ResponseView;
