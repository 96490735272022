// ContainerView.js
import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, FormControl, InputLabel, IconButton } from "@mui/material";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../../../utils";
import BlockActions from "../BlockActions/BlockActions";
import CarosalModal from "./CarosalModal"; // Import CarosalModal component
import { styled } from "@mui/material/styles";
import "../ContainerView/ContainerView.scss";
import CarouselSvg from "../../../../assets/Carousel.svg"; // Import your SVG file
import { InfoOutlined } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { GET } from "../../../../config/Api";
import { Constants } from "../../../../config/Constants";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

// Extracted styles and components
const Image = styled("span")(({ theme }) => ({
  position: "absolute",

  color: theme.palette.common.white,
}));

const CarosalBlockView = ({
  container,
  courseDetails,
  setmodalOpen,
  setblockModOpen,
  block,
  blockUserActivities,
  isHighlight,
  setisHighlight,
  CollapseBlocks,
  ExpandBlocks,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [collapsedState, setCollapsedState] = useState({}); // State object to track collapse state for each block
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for managing modal open/close
  const { completedModulesData } = useOutletContext() || {};

  const { cid } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [carouselContainerDetails, setCarouselContainerDetails] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    fetchContainerDetails();
  }, [block]);

  const fetchContainerDetails = async () => {
    try {
      setLoading(true);
      const response = await GET(
        `${Constants.getPublicCourseCarosalContainer}/${block?._id}/${
          courseDetails?._id
        }?userType=${isModerator !== -1 ? "moderator" : ""}`,
      );
      setCarouselContainerDetails(JSON.parse(response?.data?.data));
      setLoading(false);
    } catch (error) {
      navigate("/404");
      setLoading(false);
    }
  };

  const handleClick = () => {
    if (carouselContainerDetails?.length > 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  let isLocked = completedModulesData?.modules.some((item) => {
    if (item?.module_id === container?._id && item?.status === "locked") {
      return true;
    }
    return false;
  });

  const handleCollapseToggle = (blockId) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [blockId]: !prevState[blockId],
    }));

    if (!collapsedState[blockId]) {
      CollapseBlocks(blockId);
    } else {
      ExpandBlocks(blockId);
    }
  };

  const collapsed = collapsedState[block?._id] || false;

  const isCarouselHighlighted = carouselContainerDetails?.some((block) =>
    isHighlight?.includes(block._id),
  );

  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          id="fs-privacy-label"
          sx={{
            font: "bold",
            background: "white",
            padding: "0px 5px",
            borderRadius: "10px",
          }}
        >
          {/* {" "}
          Learning Carousel: Click to learn more */}
        </InputLabel>
        <>
          {" "}
          {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
            ""
          ) : (
            <div>
              <Paper
                id="paper"
                className={`container-block mcq-block ${
                  isCarouselHighlighted ? "highlightBlock" : ""
                } ${block?.theme ? `${block?.theme}` : "defaultTheme"}`}
                style={{
                  background: `${block?.theme_type === "color" ? block?.color : ""}`,
                  border: isHighlight?.length > 0 ? "" : "1px solid",
                }}
                onMouseOver={handleHover}
                onMouseLeave={handleMouseLeave}
                elevation={3}
              >
                {isMobile && (
                  <MenuIconWrapper onClick={handleMenuClick}>
                    <MoreVertIcon fontSize="large" color="action" />
                  </MenuIconWrapper>
                )}

                {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
                  <BlockActions
                    blockId={block?._id}
                    block={block}
                    setmodalOpen={setmodalOpen}
                    setblockModOpen={setblockModOpen}
                    hidden={hidden}
                    setHidden={setHidden}
                    collapsed={collapsed}
                    handleCollapseToggle={() => handleCollapseToggle(block?._id)}
                  />
                )}

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                  }}
                  position={"relative"}
                >
                  {isLocked && (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick();
                      }}
                      position={"absolute"}
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      bgcolor={"rgba(255, 255, 255,0.5)"}
                    >
                      <Box
                        bgcolor={"#059669"}
                        position={"absolute"}
                        p={1}
                        m={1}
                        borderRadius={"50%"}
                        top={0}
                        left={0}
                        height={"40px"}
                        width={"40px"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#fff"
                          style={{ height: "24px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                          />
                        </svg>
                      </Box>
                    </Box>
                  )}
                  <Grid container spacing={2} className="grid-container-main">
                    <Grid item xs={12} sm={5} md={4} lg={3} className="module-svg-container">
                      <Image>
                        <img
                          src={CarouselSvg}
                          alt="Carousel"
                          style={{ marginTop: "3rem", width: "270px", height: "auto" }}
                        />
                        <InputLabel
                          sx={{
                            font: "bold",
                            background: "white",
                            borderRadius: "10px",
                            mt: "15rem",
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlined />{" "}
                          </IconButton>
                          Learning Carousel: Click to learn more
                        </InputLabel>
                      </Image>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} lg={9} className="grid-container">
                      {block?.title !== "" && <div className="module-title">{block?.title}</div>}
                      {block?.text && (
                        <p className="module-text">
                          {block?.text?.length <= 300 ? (
                            <>{block?.text?.replace(/<[^>]+>/g, " ")}</>
                          ) : (
                            <>
                              {block?.text?.replace(/<[^>]+>/g, "").substr(0, 300)}
                              <strong className="read-more">...Read more.</strong>
                            </>
                          )}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          )}
        </>
      </FormControl>

      {isModalOpen && (
        <CarosalModal
          open={isModalOpen}
          onClose={handleModalClose}
          block={block}
          cid={cid}
          dialogTitle={block?.title}
          blockUserActivities={blockUserActivities}
          isHighlight={isHighlight}
          setisHighlight={setisHighlight}
          courseDetails={courseDetails}
          isLoading={loading}
          carouselContainerDetails={carouselContainerDetails}
        />
      )}
    </>
  );
};

export default CarosalBlockView;
