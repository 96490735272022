import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Card, CircularProgress, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { countFieldsWithValues } from "../../utils";
import { Constants } from "../../config/Constants";
import { GET } from "../../config/Api";
import { useSelector } from "react-redux";
export default function UpdateProfileModal({ missingDetails, open, setOpen }) {
  const [profileCompletion, setProfileCompletion] = useState(0);
  let navigate = useNavigate();
  let UserData = useSelector((state) => state.userLogin);
  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    UserData?.data?.isLoggedIn
      ? (async () => {
          let fieldsCompleted = 0;
          try {
            //get user profile data
            const res = await GET(`${Constants.updateProfile}/${UserData?.data?.user?.id}`);
            if (res?.data?.success) {
              fieldsCompleted = countFieldsWithValues(
                res?.data?.data?.email.includes(res?.data?.data?.name) ? "" : res?.data?.data?.name,
                res?.data?.data?.job?.title,
                res?.data?.data?.job?.org,
                res?.data?.data?.about,
                res?.data?.data?.city,
                res?.data?.data?.sex,
                res?.data?.data?.phone,
                res?.data?.data?.dp,
                res?.data?.data?.country,
                res?.data?.data?.state,
              );
            }
            try {
              //API to get all languages
              const langsRes = await GET(`${Constants.course}/languages/all`);
              if (langsRes?.data?.success) {
                const preflang = langsRes?.data?.languages?.find(
                  (el) => el._id === res?.data?.data?.preferredLanguage,
                );
                const nativelang = langsRes?.data?.languages?.find(
                  (el) => el._id === res?.data?.data?.nativeLanguage,
                );

                if (preflang) {
                  fieldsCompleted += 1;
                }
                if (nativelang) {
                  fieldsCompleted += 1;
                }
              }
            } catch (error) {
              console.log(error);
            }
          } catch (error) {
            console.log(error);
          }
          let profileCompletionData = Math.floor((fieldsCompleted * 100) / 12);
          setProfileCompletion(profileCompletionData);
        })()
      : navigate("/");
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        classes={{
          paper: "fsComms_paper",
        }}
      >
        <Box
          pt={3}
          display={"flex"}
          position={"relative"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            bgcolor={"#5194bb"}
            position={"absolute"}
            p={2}
            top={"-30px"}
            left={"-30px"}
            borderRadius={"50%"}
            height={"70px"}
            width={"70px"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              // className="w-6 h-6"
              style={{ height: "40px", width: "40px", color: "white" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </Box>

          <DialogTitle id="alert-dialog-title" sx={{ pb: 0, color: "black" }} fontSize={"32px"}>
            Update your profile
          </DialogTitle>
          {/* <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton> */}
        </Box>
        <DialogContent sx={{}}>
          <Card
            elevation={2}
            sx={{ display: "flex", alignItems: "center", bgcolor: "white", mb: 2, p: 2 }}
          >
            <Box sx={{ position: "relative", display: "inline-flex", mr: 2 }}>
              <CircularProgress
                size={"5rem"}
                variant="determinate"
                color={
                  profileCompletion < 35 ? "error" : profileCompletion < 80 ? "warning" : "success"
                }
                value={profileCompletion}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  fontSize={"16px"}
                  color="text.secondary"
                >
                  {profileCompletion} %{/* {`${Math.round(props.value)}%`} */}
                </Typography>
              </Box>
            </Box>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h6" component={"h6"}>
                Profile Information
              </Typography>
              <div>
                Your profile is missing{" "}
                <strong>
                  {missingDetails.map((el, i) => {
                    return ` ${el}${el !== missingDetails[missingDetails.length - 1] ? "," : ""} `;
                  })}
                </strong>
                . Please complete your profile. <Link to={"/user-profile"}>Click here</Link>
              </div>
            </DialogContentText>
          </Card>
          <Box my={2}>
            <Button
              variant="text"
              sx={{
                mt: "10px",
                backgroundColor: "#f1f5f9",
                color: "#94a3b8",
                ":hover": { backgroundColor: "#e2e8f0" },
              }}
              fullWidth
              size="large"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
